import React from "react";
import {createSlice} from "@reduxjs/toolkit";

export const appSlice = createSlice({
    name: "app",
    initialState: {
        modal: {
            element: <></>,
            isShow: false,
            title: "",
        },
        menu: {
            element: <></>,
            isShow: false,
        },
        isLoading: false,
    },
    reducers: {
        showMenu: (state, action) => {
            const {element} = action.payload;
            state.menu.element = element;
            state.menu.isShow = true;
        },
        hideMenu: (state) => {
            state.menu.isHide = false;
        },
        showModal: (state, action) => {
            const {element, title} = action.payload;
            state.modal.element = element;
            state.modal.title = title;
            state.modal.isShow = true;
        },
        hideModal: (state) => {
            state.modal.isShow = false;
        },
        loadingData: (state) => {
            state.isLoading = true;
        },
        stopLoadingData: (state) => {
            state.isLoading = false;
        },
    },
});

export const {showMenu, hideMenu, loadingData, stopLoadingData, showModal, hideModal} = appSlice.actions;

export default appSlice.reducer;