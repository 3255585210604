import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, FormGroup, InputGroup, MenuItem, Spinner, TextArea} from "@blueprintjs/core";
import axios from "axios";
import {useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate, useParams} from "react-router";
import {Select} from "@blueprintjs/select";

export const GetHotelPage = () => {
    const [hotel, setHotel] = useState({
        name: "",
        shortName: "",
        type: "hotel",
        priceDefault: {
            "1": 0,
            "7": 0,
            "30": 0,
        },
        address: "",
    });
    const [loadResult, setLoadResult] = useState("loading");
    const {token} = useSelector(state => state.auth);
    const navigate = useNavigate();
    const {id} = useParams();

    const load = () => {
        axios.get("/api/hotels/" + id, {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadResult("notAccess");
                } else if (!data.data) {
                    setLoadResult("notFound");
                } else {
                    setLoadResult("");
                    setHotel(data.data);
                }
            });
    };

    useEffect(() => {
        if (loadResult === "loading") {
            load();
        }
    }, [loadResult]);

    return <div style={{padding: "10px"}}>
        {
            loadResult !== "loading" && loadResult === ""
                ? <>
                    <h2>{hotel.name}</h2>
                    <ButtonGroup>
                        <Button icon="edit" onClick={() => navigate("/hotels/" + hotel.id + "/edit")}>Редактировать</Button>
                    </ButtonGroup>
                    <br/><br/>
                    <table className="info">
                        <tbody>
                        <tr>
                            <td>ID</td>
                            <td>{hotel.id}</td>
                        </tr>
                        <tr>
                            <td>Название</td>
                            <td>{hotel.name}</td>
                        </tr>
                        <tr>
                            <td>Краткое название</td>
                            <td>{hotel.shortName}</td>
                        </tr>
                        <tr>
                            <td>Загруженность</td>
                            <td>{hotel.countFullPlaces}/{hotel.countPlaces} ({((hotel.countFullPlaces / hotel.countPlaces) * 100).toFixed(2)}%)</td>
                        </tr>
                        <tr>
                            <td>Тип</td>
                            <td>{hotel.type}</td>
                        </tr>
                        <tr className="bold">
                            <td colSpan={2}>Стандартные цены</td>
                        </tr>
                        <tr>
                            <td>Сутки</td>
                            <td>{hotel.priceDefault["1"]}</td>
                        </tr>
                        <tr>
                            <td>Неделя</td>
                            <td>{hotel.priceDefault["7"]}</td>
                        </tr>
                        <tr>
                            <td>Месяц</td>
                            <td>{hotel.priceDefault["30"]}</td>
                        </tr>
                        <tr className="bold">
                            <td colSpan={2}>Дополнительная информация</td>
                        </tr>
                        <tr>
                            <td>Адрес</td>
                            <td>{hotel.address}</td>
                        </tr>
                        <tr>
                            <td>Создан</td>
                            <td>{hotel.createdAt}</td>
                        </tr>
                        <tr>
                            <td>Обновлен</td>
                            <td>{hotel.updatedAt}</td>
                        </tr>
                        </tbody>
                    </table>
                </>
                : <Spinner size={100}/>
        }

    </div>
};