import React, {useEffect, useState} from "react";
import axios from "axios";
import {Intent, Spinner, Tag} from "@blueprintjs/core";
import Masonry from "react-responsive-masonry";
import MasonryResponsive from "react-responsive-masonry/lib/ResponsiveMasonry";
import {useSelector} from "react-redux";
import {
    HorizontalGridLines,
    LineSeries,
    LineSeriesCanvas,
    VerticalBarSeries,
    VerticalGridLines,
    XAxis,
    YAxis,
    XYPlot, FlexibleWidthXYPlot,
} from "react-vis";
import * as moment from "moment";
import {Container, Card} from "react-bootstrap";
import Badge from "react-bootstrap/Badge";

export const StatsPage = () => {
    const [loadResult, setLoadResult] = useState("loading");
    const [count, setCount] = useState({});
    const {token} = useSelector(state => state.auth);

    const getData = () => {
        axios.get("/api/clients/clientLogs/countAmount", {headers: {token}}).then(data => {
            setCount(data.data);
            setLoadResult("ok");
        });
    };

    useEffect(() => {
        if (loadResult === "loading" || loadResult === "update") {
            getData();
        }
    }, [loadResult]);

    const getMonthBarData = (data) => {
        return data.map((month, i) => {
            return {"x": moment().month(i).format("MMM"), "y": month}
        });
    };

    const getWeekBarData = (data) => {
        return data.map((week, i) => {
            return {"x": String(i + 1), "y": week}
        });
    };

    const getYearBarData = (data) => {
        return data.map((week, i) => {
            return {"x": moment().subtract(6-i, "year").month(1).format("YYYY"), "y": week}
        });
    };

    const CountValue = props => {
        return <p style={{fontSize: "1.4em"}}>
            <b>{props.value} ₽ {props.percent ? <Badge
                bg={props.percent > 0 ? "success" : "danger"}>{props.percent}%
                ({props.valueLast} ₽)</Badge> : null}</b></p>
    };

    return <Container>
        {
            loadResult === "ok"
                ? <>
                    <MasonryResponsive columnsCountBreakPoints={{350: 1, 600: 2, 900: 3}}>
                        <Masonry style={{width: "100%"}} gutter="10px">
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        За эту неделю
                                    </Card.Title>
                                    <CountValue value={count.countIncome.countWeek} percent={count.percentCountIncome.week} valueLast={count.countIncome.countLastWeek}/>
                                    <FlexibleWidthXYPlot xType="ordinal" height={300}>
                                        <XAxis/>
                                        <YAxis/>
                                        <VerticalBarSeries
                                            data={getWeekBarData(count.countIncomePeriods.last.week)}
                                        />
                                        <VerticalBarSeries
                                            data={getWeekBarData(count.countIncomePeriods.current.week)}
                                        />
                                    </FlexibleWidthXYPlot>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        За этот месяц
                                    </Card.Title>
                                    <CountValue value={count.countIncome.countMonth} percent={count.percentCountIncome.month} valueLast={count.countIncome.countLastMonth}/>
                                    <FlexibleWidthXYPlot xType="ordinal" height={300}>
                                        <XAxis tickLabelAngle={-45}/>
                                        <YAxis/>
                                        <VerticalBarSeries
                                            data={getMonthBarData(count.countIncomePeriods.current.month)}
                                        />
                                        <VerticalBarSeries
                                            data={getMonthBarData(count.countIncomePeriods.last.month)}
                                        />
                                        <LineSeries
                                            data={getMonthBarData(count.countIncomePeriods.current.fullMonth)}
                                        />
                                    </FlexibleWidthXYPlot>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        За этот год
                                    </Card.Title>
                                    <CountValue value={count.countIncome.countYear} percent={count.percentCountIncome.year} valueLast={count.countIncome.countLastYear}/>
                                    <FlexibleWidthXYPlot xType="ordinal" height={300}>
                                        <XAxis tickLabelAngle={-45}/>
                                        <YAxis/>
                                        <VerticalBarSeries
                                            data={getYearBarData(count.countIncomePeriods.current.year)}
                                        />
                                    </FlexibleWidthXYPlot>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        За всё время
                                    </Card.Title>
                                    <CountValue value={count.countIncome.countAll}/>
                                    <FlexibleWidthXYPlot xType="ordinal" height={300}>
                                        <XAxis tickLabelAngle={-45}/>
                                        <YAxis/>
                                        <LineSeries
                                            data={getYearBarData(count.countIncomePeriods.current.fullYear)}
                                        />
                                    </FlexibleWidthXYPlot>
                                </Card.Body>
                            </Card>
                        </Masonry>
                    </MasonryResponsive>
                </>
                : null
        }
        {
            loadResult === "loading"
                ? <Spinner size={100}/>
                : null
        }
    </Container>
};