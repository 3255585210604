import React, {useCallback, useEffect, useState} from "react";
import {
    Button,
    ButtonGroup,
    Callout,
    FormGroup,
    InputGroup, Intent,
    MenuItem,
    Spinner,
    Switch,
    TextArea
} from "@blueprintjs/core";
import axios from "axios";
import {useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate, useParams} from "react-router";
import {MultiSelect, Select} from "@blueprintjs/select";
import moment from "moment";
import {DateInput} from "@blueprintjs/datetime";
import {format, parse} from "date-fns";

export const EditUserPage = () => {
    const [user, setUser] = useState({
        name: "",
        nickName: "",
        accessProducts: {},
        superUser: false,
    });
    const [hotels, setHotels] = useState([{}]);
    const [sendResult, setSendResult] = useState("");
    const [loadResult, setLoadResult] = useState("loading");
    const {token} = useSelector(state => state.auth);
    const navigate = useNavigate();
    const {id} = useParams();

    const dateFnsFormat = "dd/MM/yyyy";
    const formatDate = useCallback((date) => format(date, dateFnsFormat), []);
    const parseDate = useCallback((str) => parse(str, dateFnsFormat, new Date()), []);

    const send = () => {
        setSendResult("loading");
        axios.put("/api/users/" + id, {
            name: user.name,
            nickName: user.nickName,
            accessProducts: user.accessProducts,
            superUser: user.superUser,
        }, {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setSendResult("notAccess");
                } else if (data.data === "placeNotEmpty") {
                    setSendResult("placeNotEmpty");
                } else {
                    navigate("/settings/users");
                }
            })
    };

    const getData = () => {
        axios.get("/api/users/" + id, {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadResult("notAccess");
                } else if (data.data === []) {
                    setLoadResult("notFound");
                } else {
                    setUser(data.data.user);
                    if (!data.data.user.accessProducts) {
                        setUser({
                            ...data.data.user,
                            accessProducts: {
                                hotel: {
                                    clients: 0,
                                    hotels: 0,
                                    hotelsUnit: [],
                                },
                            },
                        })
                    }
                    axios.get("/api/hotels", {headers: {token}})
                        .then(data2 => {
                            if (data2.status === 403) {
                                setLoadResult("notAccess");
                            } else if (data2.data === []) {
                                setLoadResult("notFound");
                            } else {
                                setHotels(data2.data);
                                setLoadResult("ok");
                            }
                        })
                }
            });
    };

    useEffect(() => {
        if (loadResult === "loading") {
            getData();
        }
    }, [loadResult]);

    return <div style={{padding: "10px"}}>
        {
            loadResult === "loading"
                ? <Spinner size={100}/>
                : null
        }
        {
            loadResult === "ok"
                ? <>
                    <h2>Редактирование пользователя</h2>
                    <div style={{display: "flex", gap: "10px"}}>
                        <FormGroup
                            label="Имя"
                            labelFor="name-input"
                        >
                            <InputGroup
                                id="name-input"
                                defaultValue={user.name}
                                onChange={(e) => {
                                    setUser({
                                        ...user,
                                        name: e.target.value,
                                    })
                                }}
                            />
                        </FormGroup>
                        <FormGroup
                            label="Логин"
                            labelFor="nickName-input"
                        >
                            <InputGroup
                                id="nickName-input"
                                defaultValue={user.nickName}
                                onChange={(e) => {
                                    setUser({
                                        ...user,
                                        nickName: e.target.value,
                                    })
                                }}
                            />
                        </FormGroup>
                    </div>
                    <Switch checked={user.superUser} onChange={(e) => setUser({
                        ...user,
                        superUser: e.target.checked,
                    })} labelElement="Супер-пользователь" />
                    {
                        !user.superUser
                            ? <div style={{padding: "10px"}}>
                                <FormGroup
                                    label="Уровень доступа к клиентам"
                                    labelFor="accessProducts-hotel-clients-input"
                                >
                                    <InputGroup
                                        id="accessProducts-hotel-clients-input"
                                        defaultValue={user.accessProducts?.hotel?.clients}
                                        type="number"
                                        onChange={(e) => {
                                            setUser({
                                                ...user,
                                                accessProducts: {
                                                    ...user.accessProducts,
                                                    hotel: {
                                                        ...user.accessProducts?.hotel,
                                                        clients: e.target.value,
                                                    },
                                                },
                                            })
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Уровень доступа к отелям"
                                    labelFor="accessProducts-hotel-hotels-input"
                                >
                                    <InputGroup
                                        id="accessProducts-hotel-hotels-input"
                                        type="number"
                                        defaultValue={user.accessProducts?.hotel?.hotels}
                                        onChange={(e) => {
                                            setUser({
                                                ...user,
                                                accessProducts: {
                                                    ...user.accessProducts,
                                                    hotel: {
                                                        ...user.accessProducts?.hotel,
                                                        hotels: e.target.value,
                                                    },
                                                },
                                            })
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Отели в управлении"
                                >
                                    <MultiSelect
                                        items={hotels}
                                        itemRenderer={(item) => {
                                            return <MenuItem text={item.shortName} selected={user.accessProducts?.hotel?.hotelsUnit?.includes(item.id)} onClick={() => setUser({
                                                ...user,
                                                accessProducts: {
                                                    ...user.accessProducts,
                                                    hotel: {
                                                        ...user.accessProducts?.hotel,
                                                        hotelsUnit: user.accessProducts?.hotel?.hotelsUnit?.includes(item.id) ? user.accessProducts?.hotel?.hotelsUnit?.toSpliced(user.accessProducts?.hotel?.hotelsUnit?.indexOf(item.id), 1) : user.accessProducts?.hotel?.hotelsUnit?.concat([item.id]),
                                                    },
                                                },
                                            })}/>
                                        }}
                                        // filterable={false}
                                        selectedItems={user.accessProducts?.hotel?.hotelsUnit}
                                        tagRenderer={(item) => {
                                            return <>{(hotels.find(h => h.id === item)).shortName}</>;
                                        }}
                                    />
                                </FormGroup>
                            </div>
                            : null
                    }
                    {
                        sendResult === "placeNotEmpty"
                            ? <Callout title="Вы выбрали не свободное место" intent={Intent.DANGER}/>
                            : null
                    }
                    <Button onClick={send} loading={sendResult === "loading"}>Сохранить</Button>
                </>
                : null
        }
    </div>
};