import React, {useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {login, logout} from "./Store/authSlice";
import {hideModal} from "./Store/appSlice";
import axios from "axios";
import {Center} from "react-containers";
import {Dialog, OverlayToaster, Spinner} from "@blueprintjs/core";
import {Position} from "@blueprintjs/core/lib/esnext/common/position";

function App() {
    const {isLoading} = useSelector(state => state.auth);
    const modal = useSelector(state => state.app.modal);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoading) {
            const token = localStorage.getItem('token');
            if (!token) {
                dispatch(logout());
                localStorage.removeItem('token');
                navigate('/login');
            } else {
                axios.get('/api/auth/me', {headers: {token}})
                    .then(data => {
                        if (data.status === 403) {
                            localStorage.removeItem('token');
                            dispatch(logout());
                            navigate('/login');
                        } else {
                            console.log(data.data);
                            dispatch(login({
                                token,
                                userData: data.data.user,
                            }));
                        }
                    });
            }
        }
    }, [isLoading]);

    return <div className="App">
        {
            isLoading
                ? <div style={{width: "100vw", height: "100vh", overflowX: "hidden"}}>
                    <Center>
                        <Spinner size={100}/>
                    </Center>
                </div>
                : <Outlet/>
        }
        <Dialog className="stack-modal" isOpen={modal.isShow} onClose={() => dispatch(hideModal())} title={modal.title}>
            {modal.element}
        </Dialog>
    </div>
}

export default App;

export const AppToaster = OverlayToaster.create({
    position: Position.BOTTOM,
});