import React, {useEffect, useState} from "react";
import {
    Callout, Card, CardList,
    Dialog,
    DialogBody,
    DialogFooter,
    FormGroup,
    InputGroup,
    Intent, Menu, MenuDivider, MenuItem, OverlayToaster, PanelStack2,
    Spinner
} from "@blueprintjs/core";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate} from "react-router";
import moment from "moment";
import QRCode from "react-qr-code";
import {useMediaQuery} from "react-responsive";
import {useSearchParams} from "react-router-dom";
import {AppToaster} from "../../App";
import {PayClientModal} from "../../Components/Modals/Clients/PayClientModal";
import {showModal, hideModal} from "../../Store/appSlice";
import {ListGroup, Button, ButtonGroup} from "react-bootstrap";
import Icon from "@mdi/react";
import {
    mdiAccountCash,
    mdiAccountPlus,
    mdiDelete, mdiFilter, mdiFilterMinus,
    mdiInformationOutline,
    mdiInformationVariant,
    mdiPencil, mdiReload
} from "@mdi/js";

export const ClientsPage = () => {
    const [clients, setClients] = useState([{}]);
    const [loadingResult, setLoadingResult] = useState("loading");
    const {token} = useSelector(state => state.auth);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState({
        title: "",
        content: <></>,
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isMobile = useMediaQuery({
        query: "(max-width: 600px)",
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const filterEnable = searchParams.get("filterEnable");
    const filterHotel = searchParams.get("hotel");
    const filterRoom = searchParams.get("room");
    const filterPayDate = searchParams.get("payDate");
    const filterNoPlace = searchParams.get("noPlace");

    const handleDialogOpen = (title, element) => {
        dispatch(showModal({title, element}));
    };

    const FilterDialog = () => {
        const handleClick = (url) => {
            navigate(url);
            setLoadingResult("loading");
            setDialogIsOpen(false);
        };

        return <>
            <DialogBody>
                <Menu large>
                    <MenuDivider title="По дате оплаты"/>
                    <MenuItem text="Просрочки" onClick={() => handleClick("/clients?filterEnable=true&payDate=delay")}/>
                    <MenuItem text="Сегодня" onClick={() => handleClick("/clients?filterEnable=true&payDate=today")}/>
                    <MenuItem text="Завтра" onClick={() => handleClick("/clients?filterEnable=true&payDate=tomorrow")}/>
                    <MenuItem text="Юр.лица на этой неделе"
                              onClick={() => handleClick("/clients?filterEnable=true&payDate=tomorrowLegalEntity")}/>
                    <MenuDivider title="Прочее"/>
                    <MenuItem text="Выселенные" onClick={() => handleClick("/clients?filterEnable=true&noPlace=true")}/>
                </Menu>
            </DialogBody>
            <DialogFooter actions={<>
                <Button onClick={() => setDialogIsOpen(false)}>Отмена</Button>
            </>}/>
        </>
    };

    const getColor = (client) => {
        if (client.legalEntityEnable) {
            if (moment(client.lastPay).isBefore(moment(), "d")) {
                return "rgb(249 155 155)";
            } else if (moment(client.lastPay).isSame(moment(), "d")) {
                return "rgb(249 242 155)";
            } else if (!moment(client.lastPay).add(-7, "d").isAfter(moment(), "d")) {
                return "rgb(193 193 255)";
            }
        } else {
            if (moment(client.lastPay).isBefore(moment(), "d")) {
                return "rgb(249 155 155)";
            } else if (moment(client.lastPay).isSame(moment(), "d")) {
                return "rgb(249 242 155)";
            } else if (!moment(client.lastPay).add(-1, "d").isAfter(moment(), "d")) {
                return "rgb(193 193 255)";
            }
        }
    };

    const getData = () => {
        axios.get("/api/clients", {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadingResult("notAccess");
                } else if (data.data === []) {
                    setLoadingResult("notFound");
                } else {
                    if (filterEnable) {
                        if (filterRoom) {
                            setClients(data.data.filter(c => c.place.room.id === Number(filterRoom)).sort((a, b) => {
                                let dateA = new Date(a.lastPay);
                                let dateB = new Date(b.lastPay);

                                return dateA - dateB;
                            }));
                        } else if (filterHotel) {
                            setClients(data.data.filter(c => c.place.room.hotel.id === Number(filterHotel)).sort((a, b) => {
                                let dateA = new Date(a.lastPay);
                                let dateB = new Date(b.lastPay);

                                return dateA - dateB;
                            }));
                        } else if (filterPayDate) {
                            if (filterPayDate === "today") {
                                setClients(data.data.filter(c => c.placeId).filter(c => moment(c.lastPay).isSame(moment(), "d")));
                            } else if (filterPayDate === "tomorrow") {
                                setClients(data.data.filter(c => c.placeId).filter(c => moment(c.lastPay).add(-1, "d").isSame(moment(), "d")));
                            } else if (filterPayDate === "delay") {
                                setClients(data.data.filter(c => c.placeId).filter(c => moment(c.lastPay).isBefore(moment(), "d")));
                            } else if (filterPayDate === "tomorrowLegalEntity") {
                                setClients(data.data.filter(c => c.placeId).filter(c => !moment(c.lastPay).add(-7, "d").isAfter(moment(), "d") && c.legalEntityEnable));
                            }
                        } else if (filterNoPlace) {
                            setClients(data.data.filter(c => !c.placeId));
                        } else {
                            navigate("/clients");
                            setLoadingResult("loading");
                        }
                    } else {
                        setClients(data.data.filter(c => c.placeId).sort((a, b) => {
                            let dateA = new Date(a.lastPay);
                            let dateB = new Date(b.lastPay);

                            return dateA - dateB;
                        }));
                    }
                    setLoadingResult("ok");
                }
            })
    };

    const deleteData = (id) => {
        axios.delete("/api/clients/" + id, {headers: {token}})
            .then(() => {
                setLoadingResult("update");
            })
    };

    useEffect(() => {
        if (loadingResult === "loading" || loadingResult === "update") {
            getData();
        }
    }, [loadingResult]);

    return <div style={{padding: "10px", width: "calc(100%)"}}>
        {
            !isMobile
                ? <ButtonGroup>
                    <Button onClick={() => navigate("/clients/add")}><Icon path={mdiAccountPlus} size={1}/> Заселить</Button>
                    <Button onClick={() => setLoadingResult("update")}
                            loading={loadingResult === "update"}><Icon path={mdiReload} size={1}/> Обновить</Button>
                    <Button icon="filter" onClick={() => handleDialogOpen(`Фильтры`,
                        <FilterDialog/>)}><Icon path={mdiFilter} size={1}/> Фильтры</Button>
                    {
                        filterEnable
                            ? <Button onClick={() => {
                                navigate("/clients");
                                setLoadingResult("loading")
                            }}><Icon path={mdiFilterMinus} size={1}/> Сбросить фильтры</Button>
                            : null
                    }
                </ButtonGroup>
                : <ButtonGroup size="lg">
                    <Button onClick={() => navigate("/clients/add")}><Icon path={mdiAccountPlus} size={1}/></Button>
                    <Button onClick={() => setLoadingResult("update")}
                            loading={loadingResult === "update"}><Icon path={mdiReload} size={1}/></Button>
                    <Button onClick={() => handleDialogOpen(`Фильтры`,
                        <FilterDialog/>)}><Icon path={mdiFilter} size={1}/></Button>
                    {
                        filterEnable
                            ? <Button onClick={() => {
                                navigate("/clients");
                                setLoadingResult("loading")
                            }}><Icon path={mdiFilterMinus} size={1}/></Button>
                            : null
                    }
                </ButtonGroup>
        }
        <br/><br/>
        {
            loadingResult === "ok"
                ? !isMobile ? <ListGroup>
                    {
                        clients.map(r => {
                            return <ListGroup.Item style={{background: getColor(r)}}>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <div>
                                        <span>{r.fullNo} {r.fullName}</span> <span className="text-muted">{moment(r.lastPay).format("DD/MM ddd")}</span>
                                    </div>
                                    <div>
                                        <ButtonGroup size="sm">
                                            <Button variant="outline-secondary"
                                                    onClick={() => handleDialogOpen(`Онлайн оплата ${r.fullNo} ${r.name} ${r.city}`,
                                                        <PayClientModal onClose={() => dispatch(hideModal())}
                                                                        onUpdate={() => {
                                                                            setLoadingResult("loading");
                                                                            dispatch(hideModal())
                                                                        }} id={r.id} client={r}/>)}><Icon path={mdiAccountCash} color="black" size={1}/></Button>
                                            <Button variant="outline-secondary" onClick={() => navigate("/clients/" + r.id)}><Icon path={mdiInformationVariant} size={1} color="black"/></Button>
                                            <Button variant="outline-secondary" onClick={() => navigate("/clients/" + r.id + "/edit")}><Icon path={mdiPencil} color="black" size={1}/></Button>
                                            <Button variant="outline-danger" onClick={() => deleteData(r.id)} intent={Intent.DANGER}><Icon path={mdiDelete} color="black" size={1}/></Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </ListGroup.Item>
                        })
                    }
                </ListGroup> : <ListGroup>
                    {
                        clients.map(client => {
                            return <ListGroup.Item style={{background: getColor(client)}}>
                                <span><b>{client.fullNo} {client.fullName}</b> <span className="text-muted">{moment(client.lastPay).format("DD/MM ddd")}</span></span>
                                <br/>
                                <ButtonGroup size="sm">
                                    <Button variant="outline-primary" style={{color: "black"}}
                                            onClick={() => handleDialogOpen(`Онлайн оплата ${client.fullNo} ${client.fullName}`,
                                                <PayClientModal onClose={() => dispatch(hideModal())} onUpdate={() => {
                                                    setLoadingResult("loading");
                                                    dispatch(hideModal())
                                                }} id={client.id} client={client}/>)}><Icon path={mdiAccountCash} size={1} color="black"/> Оплата</Button>
                                    <Button variant="outline-primary" style={{color: "black"}}
                                            onClick={() => navigate('/clients/' + client.id)}><Icon path={mdiInformationVariant} size={1} color="black"/> Подробнее</Button>
                                </ButtonGroup>
                            </ListGroup.Item>
                        })
                    }
                </ListGroup>
                : <Spinner size={100}/>
        }
        <Dialog isOpen={dialogIsOpen} onClose={() => setDialogIsOpen(false)} title={dialogContent.title}>
            {dialogContent.content}
        </Dialog>
    </div>
};