import {createSlice} from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        isAuth: false,
        token: "",
        userData: {
            nickName: "",
            accesses: `{"hostels":0,"clients":0}`,
            hostels: `[]`,
            superUser: 0,
        },
        isLoading: true,
    },
    reducers: {
        login: (state, action) => {
            const {token, userData} = action.payload;
            state.token = token;
            state.userData = {
                nickName: userData.nickName,
                name: userData.name,
                accesses: userData.accesses,
                hostels: userData.hostels,
                superUser: userData.superUser,
            };
            state.isAuth = true;
            state.isLoading = false;
        },
        logout: state => {
            state.token = "";
            state.userData = {
                nickName: "",
                accesses: {hostels:0,clients:0},
                hostels: [],
                superUser: 0,
            };
            localStorage.removeItem("token");
            state.isAuth = false;
            state.isLoading = false;
        },
        refresh: state => {
            state.loading = true;
        },
    },
});

export const {login, logout, refresh} = authSlice.actions;

export default authSlice.reducer;