import React, {useCallback, useEffect, useState} from "react";
import {
    ButtonGroup,
    Callout,
    FormGroup,
    InputGroup, Intent,
    MenuItem,
    Spinner,
    Switch,
    TextArea
} from "@blueprintjs/core";
import axios from "axios";
import {useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate, useParams} from "react-router";
import {Select} from "@blueprintjs/select";
import moment from "moment";
import {DateInput} from "@blueprintjs/datetime";
import {format, parse} from "date-fns";
import Masonry from "react-responsive-masonry";
import {Card, Dropdown, Form, Button} from "react-bootstrap";
import MasonryResponsive from "react-responsive-masonry/lib/ResponsiveMasonry";

export const EditClientPage = () => {
    const [client, setClient] = useState({
        name: "",
        city: "",
        placeId: 1,
        lastPay: moment().toISOString(),
        legalEntityEnable: false,
        legalEntityType: "ie",
        legalEntityName: "",
        priceCustomPolicyEnable: false,
        priceCustomPolicy: {
            "1": 0,
            "7": 0,
            "30": 0,
        },
        contacts: {
            phone: "",
            whatsapp: "",
        },
    });
    const [searchPlace, setSearchPlace] = useState("");
    const [places, setPlaces] = useState([{}]);
    const [sendResult, setSendResult] = useState("");
    const [loadResult, setLoadResult] = useState("loading");
    const {token} = useSelector(state => state.auth);
    const navigate = useNavigate();
    const {id} = useParams();

    const send = () => {
        setSendResult("loading");
        axios.put("/api/clients/" + id, {
            name: client.name,
            city: client.city,
            placeId: client.placeId,
            lastPay: client.lastPay,
            legalEntityEnable: client.legalEntityEnable,
            legalEntityType: client.legalEntityType,
            legalEntityName: client.legalEntityName,
            priceCustomPolicyEnable: client.priceCustomPolicyEnable,
            priceCustomPolicy: client.priceCustomPolicy,
            contacts: client.contacts,
        }, {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setSendResult("notAccess");
                } else if (data.data === "placeNotEmpty") {
                    setSendResult("placeNotEmpty");
                } else {
                    navigate("/clients");
                }
            })
    };

    const getData = () => {
        axios.get("/api/clients/" + id, {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadResult("notAccess");
                } else if (data.data === []) {
                    setLoadResult("notFound");
                } else {
                    let d = data.data;
                    if (!d.priceCustomPolicyEnable) {
                        d = {
                            ...d,
                            priceCustomPolicy: {
                                "1": 0,
                                "7": 0,
                                "30": 0,
                            },
                        }
                    }
                    if (!d.contacts) {
                        d = {
                            ...d,
                            contacts: {
                                phone: "",
                                whatsapp: "",
                            },
                        }
                    }
                    setClient(d);
                    axios.get("/api/places", {headers: {token}})
                        .then(data2 => {
                            if (data2.status === 403) {
                                setLoadResult("notAccess");
                            } else if (data2.data === []) {
                                setLoadResult("notFound");
                            } else {
                                setPlaces(data2.data.filter(place => place.id === data.data.placeId || !place.client));
                                console.log(data2.data.filter(place => place.id === data.data.placeId || !place.client));
                                setLoadResult("ok");
                            }
                        })
                }
            });
    };

    useEffect(() => {
        if (loadResult === "loading") {
            getData();
        }
    }, [loadResult]);

    const legalEntityTypesList = [
        {
            name: "Выберите тип",
            value: null,
        },
        {
            name: "ИП",
            value: "ie",
        },
        {
            name: "ООО",
            value: "llc",
        },
        {
            name: "ОАО",
            value: "pc",
        },
        {
            name: "ЗАО",
            value: "pjsc",
        },
        {
            name: "ПАО",
            value: "cjsc",
        },
    ];

    return <div style={{padding: "10px"}}>
        {
            loadResult === "loading"
                ? <Spinner size={100}/>
                : null
        }
        {
            loadResult === "ok"
                ? <>
                    <h2>Редактирование клиента</h2>
                    <MasonryResponsive columnsCountBreakPoints={{350: 1, 600: 2, 900: 3}}>
                        <Masonry gutter="10px">
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Основная информация
                                    </Card.Title>
                                    <Form.Group>
                                        <Form.Label htmlFor="name-input">Имя</Form.Label>
                                        <Form.Control
                                            id="name-input"
                                            aria-describedby="name-help-input"
                                            disabled={client.legalEntityEnable}
                                            defaultValue={client.name}
                                            onChange={(e) => {
                                                setClient({
                                                    ...client,
                                                    name: e.target.value,
                                                })
                                            }}
                                        />
                                        <Form.Text id="name-help-input" muted>
                                            Вводить строго полное имя
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label htmlFor="city-input">Город</Form.Label>
                                        <Form.Control
                                            id="city-input"
                                            disabled={client.legalEntityEnable}
                                            defaultValue={client.city}
                                            onChange={(e) => {
                                                setClient({
                                                    ...client,
                                                    city: e.target.value,
                                                })
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Койко-место</Form.Label>
                                        <Dropdown>
                                            <Dropdown.Toggle style={{width: "100%"}} variant="outline-secondary">
                                                {(places.find(p => p.id === client.placeId)).fullNo}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu style={{width: "100%", height: "200px", overflowY: "scroll"}}>
                                                <Form.Control value={searchPlace}
                                                              onInput={(e) => setSearchPlace(e.target.value)}/>
                                                {
                                                    places.filter(p => !searchPlace || p.fullNo.includes(searchPlace)).map(p => {
                                                        return <Dropdown.Item
                                                            onClick={() => setClient({
                                                                ...client,
                                                                placeId: p.id,
                                                            })}>{p.fullNo}</Dropdown.Item>
                                                    })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Form.Text muted>Показаны только свободные</Form.Text>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Контакты
                                    </Card.Title>
                                    <Form.Group>
                                        <Form.Label htmlFor="contacts-phone-input">Номер телефона</Form.Label>
                                        <Form.Control
                                            id="contacts-phone-input"
                                            aria-describedby="contacts-phone-help-input"
                                            defaultValue={client.contacts.phone}
                                            onChange={(e) => {
                                                setClient({
                                                    ...client,
                                                    contacts: {
                                                        ...client.contacts,
                                                        phone: e.target.value
                                                    },
                                                })
                                            }}
                                        />
                                        <Form.Text id="contacts-phone-help-input" muted>
                                            В формате +7хххххххххх (без пробелов, скобок и тире)
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label htmlFor="contacts-phone-input">Номер WhatsApp</Form.Label>
                                        <Form.Control
                                            id="contacts-whatsapp-input"
                                            aria-describedby="contacts-whatsapp-help-input"
                                            defaultValue={client.contacts.whatsapp}
                                            onChange={(e) => {
                                                setClient({
                                                    ...client,
                                                    contacts: {
                                                        ...client.contacts,
                                                        whatsapp: e.target.value
                                                    },
                                                })
                                            }}
                                        />
                                        <Form.Text id="contacts-whatsapp-help-input" muted>
                                            В формате +7хххххххххх (без пробелов, скобок и тире)
                                        </Form.Text>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Юр. лицо
                                    </Card.Title>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label="Клиент является юр.лицом"
                                        checked={client.legalEntityEnable} onChange={(e) => setClient({
                                        ...client,
                                        legalEntityEnable: e.target.checked,
                                    })}
                                    />
                                    {
                                        client.legalEntityEnable
                                            ? <>
                                                <Form.Group>
                                                    <Form.Label>Вид компании</Form.Label>
                                                    <Dropdown>
                                                        <Dropdown.Toggle style={{width: "100%"}} variant="outline-secondary">
                                                            {(legalEntityTypesList.find(item => item.value === client.legalEntityType)).name}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu style={{width: "100%", height: "200px", overflowY: "scroll"}}>
                                                            {
                                                                legalEntityTypesList.map(item => {
                                                                    return <Dropdown.Item
                                                                        onClick={() => setClient({
                                                                            ...client,
                                                                            legalEntityType: item.value,
                                                                        })}>{item.name}</Dropdown.Item>
                                                                })
                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label htmlFor="legal-entity-name-input">Название</Form.Label>
                                                    <Form.Control
                                                        id="legal-entity-name-input"
                                                        aria-describedby="legal-entity-name-help-input"
                                                        defaultValue={client.legalEntityName}
                                                        onChange={(e) => {
                                                            setClient({
                                                                ...client,
                                                                legalEntityName: e.target.value,
                                                            })
                                                        }}
                                                    />
                                                    <Form.Text id="name-help-input" muted>
                                                        Вводить без приставки (ИП, ООО и т.д.)
                                                    </Form.Text>
                                                </Form.Group>
                                            </>
                                            : null
                                    }
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Индифидуальная ценовая политика
                                    </Card.Title>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        label="Применить"
                                        checked={client.priceCustomPolicyEnable} onChange={(e) => setClient({
                                        ...client,
                                        priceCustomPolicyEnable: e.target.checked,
                                    })}
                                    />
                                    {
                                        client.priceCustomPolicyEnable
                                            ? <>
                                                <Form.Group>
                                                    <Form.Label htmlFor="price1-input">Сутки</Form.Label>
                                                    <Form.Control
                                                        id="price1-input"
                                                        defaultValue={client.priceCustomPolicy["1"]}
                                                        onChange={(e) => {
                                                            setClient({
                                                                ...client,
                                                                priceCustomPolicy: {
                                                                    ...client.priceCustomPolicy,
                                                                    "1": e.target.value,
                                                                },
                                                            })
                                                        }}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label htmlFor="price7-input">Неделя</Form.Label>
                                                    <Form.Control
                                                        id="price7-input"
                                                        defaultValue={client.priceCustomPolicy["7"]}
                                                        onChange={(e) => {
                                                            setClient({
                                                                ...client,
                                                                priceCustomPolicy: {
                                                                    ...client.priceCustomPolicy,
                                                                    "7": e.target.value,
                                                                },
                                                            })
                                                        }}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label htmlFor="price30-input">Месяц</Form.Label>
                                                    <Form.Control
                                                        id="price30-input"
                                                        defaultValue={client.priceCustomPolicy["30"]}
                                                        onChange={(e) => {
                                                            setClient({
                                                                ...client,
                                                                priceCustomPolicy: {
                                                                    ...client.priceCustomPolicy,
                                                                    "30": e.target.value,
                                                                },
                                                            })
                                                        }}
                                                    />
                                                </Form.Group>
                                            </>
                                            : null
                                    }
                                </Card.Body>
                            </Card>
                        </Masonry>
                    </MasonryResponsive>
                    {
                        sendResult === "placeNotEmpty"
                            ? <Callout title="Вы выбрали не свободное место" intent={Intent.DANGER}/>
                            : null
                    }
                    <Button style={{width: "100%"}} onClick={send} disabled={sendResult === "loading"}>{sendResult === "loading" ?
                        <i className="fa-solid fa-circle-notch fa-spin"/> : <>
                            <i className="fa-solid fa-floppy-disk"/> Сохранить</>}</Button>
                </>
                : null
        }
    </div>
};