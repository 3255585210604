import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import moment from 'moment-timezone';
import 'moment/locale/ru';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import store from "./Store/index";
import {Provider} from "react-redux";
import {LoginPage} from "./Pages/Auth/LoginPage";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/table/lib/css/table.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import 'react-day-picker/dist/style.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import "react-vis/dist/style.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import './index.scss';
import './env.css';

import {OutletPage} from "./Pages/OutletPage";
import {HotelsPage} from "./Pages/Hotels/HotelsPage";
import {AddHotelPage} from "./Pages/Hotels/AddHotelPage";
import {EditHotelPage} from "./Pages/Hotels/EditHotelPage";
import {GetHotelPage} from "./Pages/Hotels/GetHotelPage";
import {RoomsPage} from "./Pages/Rooms/RoomsPage";
import {AddRoomPage} from "./Pages/Rooms/AddRoomPage";
import {GetRoomPage} from "./Pages/Rooms/GetRoomPage";
import {PlacesPage} from "./Pages/Places/PlacesPage";
import {AddPlacePage} from "./Pages/Places/AddPlacePage";
import {ClientsPage} from "./Pages/Clients/ClientsPage";
import {AddClientPage} from "./Pages/Clients/AddClientPage";
import {EditRoomPage} from "./Pages/Rooms/EditRoomPage";
import {GetPlacePage} from "./Pages/Places/GetPlacePage";
import {GetClientPage} from "./Pages/Clients/GetClientPage";
import {EditClientPage} from "./Pages/Clients/EditClientPage";
import {SettingsPage} from "./Pages/Settings/SettingsPage";
import {PaymentTerminalsPage} from "./Pages/Settings/PaymentTerminals/PaymentTerminalsPage";
import {AddPaymentTerminalPage} from "./Pages/Settings/PaymentTerminals/AddPaymentTerminalPage";
import {UsersPage} from "./Pages/Settings/Users/UsersPage";
import {EditUserPage} from "./Pages/Settings/Users/EditUserPage";
import {IndexPage} from "./Pages/IndexPage";
import {HistoryPage} from "./Pages/History/HistoryPage";
import {StatsPage} from "./Pages/Stats/StatsPage";
import {MenuPage} from "./Pages/MenuPage";

moment.locale('ru');

const router = createBrowserRouter([
    {
        path: '',
        element: <App/>,
        children: [
            {
                path: '',
                element: <OutletPage/>,
                children: [
                    {
                        path: '',
                        element: <IndexPage/>
                    },

                    {
                        path: 'hotels',
                        element: <HotelsPage/>,
                    },
                    {
                        path: 'hotels/add',
                        element: <AddHotelPage/>,
                    },
                    {
                        path: 'hotels/:id',
                        element: <GetHotelPage/>,
                    },
                    {
                        path: 'hotels/:id/edit',
                        element: <EditHotelPage/>,
                    },

                    {
                        path: 'rooms',
                        element: <RoomsPage/>,
                    },
                    {
                        path: 'rooms/add',
                        element: <AddRoomPage/>,
                    },
                    {
                        path: 'rooms/:id',
                        element: <GetRoomPage/>,
                    },
                    {
                        path: 'rooms/:id/edit',
                        element: <EditRoomPage/>,
                    },

                    {
                        path: 'places',
                        element: <PlacesPage/>,
                    },
                    {
                        path: 'places/add',
                        element: <AddPlacePage/>,
                    },
                    {
                        path: 'places/:id',
                        element: <GetPlacePage/>,
                    },

                    {
                        path: 'clients',
                        element: <ClientsPage/>,
                    },
                    {
                        path: 'clients/add',
                        element: <AddClientPage/>,
                    },
                    {
                        path: 'clients/:id',
                        element: <GetClientPage/>,
                    },
                    {
                        path: 'clients/:id/edit',
                        element: <EditClientPage/>,
                    },

                    {
                        path: 'history',
                        element: <HistoryPage/>,
                    },

                    {
                        path: 'stats',
                        element: <StatsPage/>,
                    },

                    {
                        path: 'menu',
                        element: <MenuPage/>,
                    },

                    {
                        path: 'settings',
                        element: <SettingsPage/>,
                    },

                    {
                        path: 'settings/paymentTerminals',
                        element: <PaymentTerminalsPage/>,
                    },
                    {
                        path: 'settings/paymentTerminals/add',
                        element: <AddPaymentTerminalPage/>,
                    },

                    {
                        path: 'settings/users',
                        element: <UsersPage/>,
                    },
                    {
                        path: 'settings/users/:id/edit',
                        element: <EditUserPage/>,
                    },
                ],
            },
            {
                path: 'login',
                element: <LoginPage/>,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router}>

            </RouterProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
