import React, {useEffect, useState} from "react";
import {
    Callout, CardList,
    Dialog,
    DialogBody,
    DialogFooter,
    FormGroup,
    InputGroup,
    Intent, Menu, MenuDivider, MenuItem, OverlayToaster, PanelStack2,
    Spinner, Tag
} from "@blueprintjs/core";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate} from "react-router";
import moment from "moment";
import QRCode from "react-qr-code";
import {useMediaQuery} from "react-responsive";
import {useSearchParams} from "react-router-dom";
import {AppToaster} from "../../App";
import {PayClientModal} from "../../Components/Modals/Clients/PayClientModal";
import {showModal, hideModal} from "../../Store/appSlice";
import MasonryResponsive from "react-responsive-masonry/lib/ResponsiveMasonry";
import Masonry from "react-responsive-masonry";
import {Button, ButtonGroup, Container, Card} from "react-bootstrap";
import Icon from "@mdi/react";
import {
    mdiAccountCash, mdiAccountClock,
    mdiAccountCreditCard, mdiAccountMinus,
    mdiAccountPlus,
    mdiFilter,
    mdiFilterMinus,
    mdiPlus,
    mdiReload
} from "@mdi/js";
import Badge from "react-bootstrap/Badge";
import {HistoryCard} from "../../Components/History/HistoryCards";

export const HistoryPage = () => {
    const [history, setHistory] = useState([{}]);
    const [count, setCount] = useState({});
    const [loadingResult, setLoadingResult] = useState("loading");
    const {token} = useSelector(state => state.auth);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState({
        title: "",
        content: <></>,
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isMobile = useMediaQuery({
        query: "(max-width: 600px)",
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const filterEnable = searchParams.get("filterEnable");
    const filterHotel = searchParams.get("hotel");
    const filterRoom = searchParams.get("room");
    const filterPayDate = searchParams.get("payDate");
    const filterNoPlace = searchParams.get("noPlace");

    const handleDialogOpen = (title, element) => {
        dispatch(showModal({title, element}));
    };

    const ManualPayDialog = (props) => {
        const [days, setDays] = useState(7);

        const manualPay = () => {
            setDialogIsOpen(false);
            axios.put("/api/clients/" + props.id + "/manualPay", {days}, {headers: {token}})
                .then(() => {
                    setLoadingResult("update");
                })
        };

        return <>
            <DialogBody>
                <FormGroup
                    label="Кол-во дней"
                    labelFor="days-input"
                >
                    <InputGroup
                        id="days-input"
                        defaultValue={7}
                        onChange={(e) => {
                            setDays(e.target.value);
                            console.log(e.target.value);
                        }}
                    />
                </FormGroup>
            </DialogBody>
            <DialogFooter actions={<>
                <Button intent={Intent.PRIMARY} onClick={manualPay}>Продлить</Button>
                <Button onClick={() => setDialogIsOpen(false)}>Отмена</Button>
            </>}/>
        </>
    };

    const FilterDialog = () => {
        const handleClick = (url) => {
            navigate(url);
            setLoadingResult("loading");
            setDialogIsOpen(false);
        };

        return <>
            <DialogBody>
                <Menu large>
                    <MenuDivider title="По дате оплаты"/>
                    <MenuItem text="Просрочки" onClick={() => handleClick("/clients?filterEnable=true&payDate=delay")}/>
                    <MenuItem text="Сегодня" onClick={() => handleClick("/clients?filterEnable=true&payDate=today")}/>
                    <MenuItem text="Завтра" onClick={() => handleClick("/clients?filterEnable=true&payDate=tomorrow")}/>
                    <MenuItem text="Юр.лица на этой неделе"
                              onClick={() => handleClick("/clients?filterEnable=true&payDate=tomorrowLegalEntity")}/>
                    <MenuDivider title="Прочее"/>
                    <MenuItem text="Выселенные" onClick={() => handleClick("/clients?filterEnable=true&noPlace=true")}/>
                </Menu>
            </DialogBody>
            <DialogFooter actions={<>
                <Button onClick={() => setDialogIsOpen(false)}>Отмена</Button>
            </>}/>
        </>
    };

    const getColor = (client) => {
        if (client.legalEntityEnable) {
            if (moment(client.lastPay).isBefore(moment(), "d")) {
                return "rgb(249 155 155)";
            } else if (moment(client.lastPay).isSame(moment(), "d")) {
                return "rgb(249 242 155)";
            } else if (!moment(client.lastPay).add(-7, "d").isAfter(moment(), "d")) {
                return "rgb(193 193 255)";
            }
        } else {
            if (moment(client.lastPay).isBefore(moment(), "d")) {
                return "rgb(249 155 155)";
            } else if (moment(client.lastPay).isSame(moment(), "d")) {
                return "rgb(249 242 155)";
            } else if (!moment(client.lastPay).add(-1, "d").isAfter(moment(), "d")) {
                return "rgb(193 193 255)";
            }
        }
    };

    const getData = () => {
        axios.get("/api/clients/clientLogs", {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadingResult("notAccess");
                } else if (data.data === []) {
                    setLoadingResult("notFound");
                } else {
                    if (filterEnable) {
                        // if (filterRoom) {
                        //     setClients(data.data.filter(c => c.place.room.id === Number(filterRoom)).sort((a, b) => {
                        //         let dateA = new Date(a.lastPay);
                        //         let dateB = new Date(b.lastPay);
                        //
                        //         return dateA - dateB;
                        //     }));
                        // } else if (filterHotel) {
                        //     setClients(data.data.filter(c => c.place.room.hotel.id === Number(filterHotel)).sort((a, b) => {
                        //         let dateA = new Date(a.lastPay);
                        //         let dateB = new Date(b.lastPay);
                        //
                        //         return dateA - dateB;
                        //     }));
                        // } else if (filterPayDate) {
                        //     if (filterPayDate === "today") {
                        //         setClients(data.data.filter(c => moment(c.lastPay).isSame(moment(), "d")));
                        //     } else if (filterPayDate === "tomorrow") {
                        //         setClients(data.data.filter(c => moment(c.lastPay).add(-1, "d").isSame(moment(), "d")));
                        //     } else if (filterPayDate === "delay") {
                        //         setClients(data.data.filter(c => moment(c.lastPay).isBefore(moment(), "d")));
                        //     } else if (filterPayDate === "tomorrowLegalEntity") {
                        //         setClients(data.data.filter(c => !moment(c.lastPay).add(-7, "d").isAfter(moment(), "d") && c.legalEntityEnable));
                        //     }
                        // } else if (filterNoPlace) {
                        //     setClients(data.data.filter(c => !c.placeId));
                        // } else {
                        //     navigate("/clients");
                        //     setLoadingResult("loading");
                        // }
                    } else {
                        setHistory(data.data);
                        axios.get("/api/clients/clientLogs/countAmount", {headers: {token}}).then(data => {
                            setCount(data.data);
                            setLoadingResult("ok");
                        });
                    }
                }
            })
    };

    const deleteData = (id) => {
        axios.delete("/api/clients/" + id, {headers: {token}})
            .then(() => {
                setLoadingResult("update");
            })
    };

    const getCategoryStr = (category) => {
        switch (category) {
            case "payManual":
                return "Ручная оплата";
            case "pay":
                return "Онлайн оплата";
            case "add":
                return "Заселение";
            case "delay":
                return "Просрочка";
            case "remove":
                return "Выселение";
        }
    };

    const getCategoryIcon = category => {
        switch (category) {
            case "payManual":
                return mdiAccountCash;
            case "pay":
                return mdiAccountCreditCard;
            case "add":
                return mdiAccountPlus;
            case "delay":
                return mdiAccountClock;
            case "remove":
                return mdiAccountMinus;
        }
    };

    const getCategoryColor = category => {
        switch (category) {
            case "payManual":
                return "success";
            case "pay":
                return "success";
            case "add":
                return "success";
            case "delay":
                return "danger";
            case "remove":
                return "danger";
        }
    };

    useEffect(() => {
        if (loadingResult === "loading" || loadingResult === "update") {
            getData();
        }
    }, [loadingResult]);

    return <Container>
        {
            !isMobile
                ? <ButtonGroup>
                    <Button onClick={() => setLoadingResult("update")}
                            loading={loadingResult === "update"}><Icon path={mdiReload} size={1}/> Обновить</Button>
                    <Button onClick={() => handleDialogOpen(`Фильтры`,
                        <FilterDialog/>)}><Icon path={mdiFilter} size={1}/> Фильтры</Button>
                    {
                        filterEnable
                            ? <Button onClick={() => {
                                navigate("/history");
                                setLoadingResult("loading")
                            }}><Icon path={mdiFilterMinus}/> Сбросить фильтры</Button>
                            : null
                    }
                </ButtonGroup>
                : <ButtonGroup>
                    <Button large onClick={() => setLoadingResult("update")}
                            loading={loadingResult === "update"}><Icon path={mdiReload} size={1}/></Button>
                    <Button icon="filter" large onClick={() => handleDialogOpen(`Фильтры`,
                        <FilterDialog/>)}><Icon path={mdiFilter} size={1}/></Button>
                    {
                        filterEnable
                            ? <Button icon="filter-remove" large onClick={() => {
                                navigate("/history");
                                setLoadingResult("loading")
                            }}><Icon path={mdiFilterMinus} size={1}/></Button>
                            : null
                    }
                </ButtonGroup>
        }
        <br/><br/>
        {
            loadingResult === "ok"
                ? <MasonryResponsive columnsCountBreakPoints={{350: 2, 600: 3, 900: 4}}>
                    <Masonry gutter="10px">
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Эта неделя
                                </Card.Title>
                                <p style={{fontSize: "1.4em"}}><b>{count.countIncome.countWeek} ₽ {count.percentCountIncome.week ? <Badge bg={count.percentCountIncome.week > 0 ? "success" : "danger"}>{count.percentCountIncome.week}%</Badge> : null}</b></p>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Этот месяц
                                </Card.Title>
                                <p style={{fontSize: "1.4em"}}><b>{count.countIncome.countMonth} ₽ {count.percentCountIncome.month ? <Badge bg={count.percentCountIncome.month > 0 ? "success" : "danger"}>{count.percentCountIncome.month}%</Badge> : null}</b></p>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Этот год
                                </Card.Title>
                                <p style={{fontSize: "1.4em"}}><b>{count.countIncome.countYear} ₽ {count.percentCountIncome.year ? <Badge bg={count.percentCountIncome.year > 0 ? "success" : "danger"}>{count.percentCountIncome.year}%</Badge> : null}</b></p>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Всё время
                                </Card.Title>
                                <p style={{fontSize: "1.4em"}}><b>{count.countIncome.countAll} ₽</b></p>
                            </Card.Body>
                        </Card>
                    </Masonry>
                </MasonryResponsive>
                : null
        }
        <br/><br/>
        {
            loadingResult === "ok"
                ? !isMobile ? <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {
                        history.map(log => {
                            return <HistoryCard log={log}/>
                        })
                    }
                </div> : <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {
                        history.map(log => {
                            return <HistoryCard log={log}/>
                        })
                    }
                </div>
                : <Spinner size={100}/>
        }
        <Dialog isOpen={dialogIsOpen} onClose={() => setDialogIsOpen(false)} title={dialogContent.title}>
            {dialogContent.content}
        </Dialog>
    </Container>
};