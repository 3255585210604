import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, FormGroup, InputGroup, MenuItem, Spinner, TextArea} from "@blueprintjs/core";
import axios from "axios";
import {useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate, useParams} from "react-router";
import {Select} from "@blueprintjs/select";
import moment from "moment";

export const GetPlacePage = () => {
    const [place, setPlace] = useState({});
    const [loadResult, setLoadResult] = useState("loading");
    const {token} = useSelector(state => state.auth);
    const navigate = useNavigate();
    const {id} = useParams();

    const load = () => {
        axios.get("/api/places/" + id, {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadResult("notAccess");
                } else if (!data.data) {
                    setLoadResult("notFound");
                } else {
                    setLoadResult("");
                    setPlace(data.data);
                }
            });
    };

    useEffect(() => {
        if (loadResult === "loading") {
            load();
        }
    }, [loadResult]);

    return <div style={{padding: "10px"}}>
        {
            loadResult !== "loading" && loadResult === ""
                ? <>
                    <h2>Койко-место {place.room.hotel.shortName}/{place.room.no}/{place.no}</h2>
                    <table className="info">
                        <tbody>
                            <tr>
                                <td>ID</td>
                                <td>{place.id}</td>
                            </tr>
                            <tr>
                                <td>Отель</td>
                                <td>{place.room.hotel.name}</td>
                            </tr>
                            {
                                place.client
                                    ? <>
                                        <tr className="bold">
                                            <td colSpan={2}>Клиент</td>
                                        </tr>
                                        <tr>
                                            <td>Имя Город</td>
                                            <td>{place.client.name} {place.client.city}</td>
                                        </tr>
                                        <tr>
                                            <td>Дата оплаты</td>
                                            <td>{moment(place.client.lastPay).format("DD/MM ddd")}</td>
                                        </tr>
                                    </>
                                    : null
                            }
                            <tr className="bold">
                                <td colSpan={2}>Дополнительная информация</td>
                            </tr>
                            <tr>
                                <td>Создан</td>
                                <td>{moment(place.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                            </tr>
                            <tr>
                                <td>Обновлен</td>
                                <td>{moment(place.updatedAt).format("DD/MM/YYYY HH:mm")}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
                : <Spinner size={100}/>
        }

    </div>
};