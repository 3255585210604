import moment from "moment";
import {Button, ListGroup} from "react-bootstrap";
import React from "react";
import Icon from "@mdi/react";
import {mdiInformationVariant} from "@mdi/js";
import {useNavigate} from "react-router";

const getColor = (client) => {
    if (client.legalEntityEnable) {
        if (moment(client.lastPay).isBefore(moment(), "d")) {
            return "rgb(249 155 155)";
        } else if (moment(client.lastPay).isSame(moment(), "d")) {
            return "rgb(249 242 155)";
        } else if (!moment(client.lastPay).add(-7, "d").isAfter(moment(), "d")) {
            return "rgb(193 193 255)";
        }
    } else {
        if (moment(client.lastPay).isBefore(moment(), "d")) {
            return "rgb(249 155 155)";
        } else if (moment(client.lastPay).isSame(moment(), "d")) {
            return "rgb(249 242 155)";
        } else if (!moment(client.lastPay).add(-1, "d").isAfter(moment(), "d")) {
            return "rgb(193 193 255)";
        }
    }
};

export const ClientMiniCard = props => {
    const {client, showColor} = props;
    const navigate = useNavigate();

    return <ListGroup.Item style={{background: showColor ? getColor(client) : null}}>
        <Button onClick={() => navigate("/clients/" + client.id)} className="optibiz-transparent-btn"><i
            className="fa-solid fa-circle-info"/></Button> <b>{client.fullNo}</b> {client.fullName}
        <b>{moment(client.lastPay).format("DD/MM ddd")}</b>
    </ListGroup.Item>
};