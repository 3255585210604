import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {Callout, Intent, Spinner} from "@blueprintjs/core";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import axios from "axios";
import moment from "moment";
import {Card, ListGroup, Button, Alert, ProgressBar} from "react-bootstrap";
import {ClientMiniCard} from "../Components/Clients/ClientCards";

export const IndexPage = () => {
    const {token, userData} = useSelector(state => state.auth);
    const navigate = useNavigate();

    const CardRoomsFree = () => {
        const [loadResult, setLoadResult] = useState("loading");
        const [rooms, setRooms] = useState([]);
        const [hotels, setHotels] = useState([]);

        const getData = () => {
            axios.get("/api/hotels", {headers: {token}})
                .then(data => {
                    if (data.status === 403) {
                        setLoadResult("notAccess");
                    } else if (data.data === []) {
                        setLoadResult("notFound");
                    } else {
                        // setHotels(data.data.filter(p => p.countEmptyPlaces > 0).sort((a, b) => {
                        //     if (a.no > b.no) {
                        //         return 1;
                        //     } else if (a.no < b.no) {
                        //         return -1;
                        //     } else {
                        //         return 0;
                        //     }
                        // }));
                        setHotels(data.data);
                        setLoadResult("ok");
                    }
                });
        };

        useEffect(() => {
            if (loadResult === "loading") {
                getData();
            }
        }, [loadResult]);

        const getColor = percent => {
            if (percent >= 90) {
                return "danger";
            } else if (percent >= 60) {
                return "warning";
            } else if (percent >= 25) {
                return "success";
            } else {
                return "primary";
            }
        };

        if (loadResult === "loading") {
            return <Spinner/>
        } else if ((hotels.filter(h => h.countEmptyPlaces).length === 0 && loadResult === "ok") || loadResult === "notFound") {
            return <Alert variant="warning">
                <Alert.Heading><i className="fa-solid fa-triangle-exclamation"/> Свободных мест нет</Alert.Heading>
                <p>В хостелах, которые в вашем управлении, нет свободных койко-мест</p>
                <hr/>
                <p className="text-muted">
                    Если это не так, то проверьте всех клиентов, возможно есть те, кто уже не живет, но в системе
                    числятся
                </p>
                <Button variant="outline-warning" style={{width: "100%"}}>Открыть спикок клиентов</Button>
            </Alert>
        } else if (loadResult === "ok" && hotels.filter(h => h.countEmptyPlaces).length > 0) {
            return <Card>
                <Card.Body>
                    <Card.Title>
                        <i className="fa-solid fa-bed"/> Комнаты со свободными койко-местами
                    </Card.Title>
                    {
                        hotels.filter(h => h.countEmptyPlaces).map(h => <><Card style={{marginBottom: "5px"}}>
                            <Card.Body>
                                <Card.Title>
                                    {h.name}
                                </Card.Title>
                                <ProgressBar variant={getColor((h.countFullPlaces / h.countPlaces) * 100)} now={(h.countFullPlaces / h.countPlaces) * 100}/>
                                <span><b>Свободно: {h.countEmptyPlaces}</b></span><br/>
                                {
                                    h.rooms.filter(p => p.countEmptyPlaces > 0).sort((a, b) => {
                                        if (a.no > b.no) {
                                            return 1;
                                        } else if (a.no < b.no) {
                                            return -1;
                                        } else {
                                            return 0;
                                        }
                                    }).map(r => <><span>{r.no} (своб. {r.countEmptyPlaces})</span><br/></>)
                                }
                            </Card.Body>
                        </Card></>)
                    }
                    <Button style={{width: "100%"}}><i className="fa-solid fa-list"/> Открыть список комнат</Button>
                </Card.Body>
            </Card>
        }
    };

    const CardClientsDelay = () => {
        const [loadResult, setLoadResult] = useState("loading");
        const [clients, setClients] = useState([]);

        const getData = () => {
            axios.get("/api/clients", {headers: {token}})
                .then(data => {
                    if (data.status === 403) {
                        setLoadResult("notAccess");
                    } else if (data.data === []) {
                        setLoadResult("notFound");
                    } else {
                        setClients(data.data.filter(c => moment(c.lastPay).isBefore(moment(), "d") && c.placeId).sort((a, b) => {
                            let dateA = new Date(a.lastPay);
                            let dateB = new Date(b.lastPay);

                            return dateA - dateB;
                        }));
                        setLoadResult("ok");
                    }
                })
        };

        useEffect(() => {
            if (loadResult === "loading") {
                getData();
            }
        }, [loadResult]);

        if (loadResult === "loading") {
            return <Spinner/>
        } else if ((clients.length === 0 && loadResult === "ok") || loadResult === "notFound") {
            return <Alert variant="success">
                <Alert.Heading><i className="fa-solid fa-check"/> Доджников нет</Alert.Heading>
                <p>Должников в ваших хостелах нет. Расслабьтесь и отдыхайте!</p>
            </Alert>
        } else {
            return <Card>
                <Card.Body>
                    <Card.Title><i className="fa-regular fa-clock"/> Должники</Card.Title>
                    <ListGroup>
                        {
                            clients.map(c => <ClientMiniCard client={c}/>)
                        }
                    </ListGroup>
                    <br/>
                    <Button style={{width: "100%"}} onClick={() => navigate("/clients?filterEnable=true&payDate=delay")}><i
                        className="fa-solid fa-list"/> Открыть список должников</Button>
                </Card.Body>
            </Card>
        }
    };

    const CardClientsToday = () => {
        const [loadResult, setLoadResult] = useState("loading");
        const [clients, setClients] = useState([]);

        const getData = () => {
            axios.get("/api/clients", {headers: {token}})
                .then(data => {
                    if (data.status === 403) {
                        setLoadResult("notAccess");
                    } else if (data.data === []) {
                        setLoadResult("notFound");
                    } else {
                        setClients(data.data.filter(c => moment(c.lastPay).isSame(moment(), "d") && c.placeId).sort((a, b) => {
                            let dateA = new Date(a.lastPay);
                            let dateB = new Date(b.lastPay);

                            return dateA - dateB;
                        }));
                        setLoadResult("ok");
                    }
                })
        };

        useEffect(() => {
            if (loadResult === "loading") {
                getData();
            }
        }, [loadResult]);

        if (loadResult === "loading") {
            return <Spinner/>
        } else if ((clients.length === 0 && loadResult === "ok") || loadResult === "notFound") {
            return <Alert variant="success">
                <Alert.Heading><i className="fa-solid fa-check"/> Оплат на сегодня нет</Alert.Heading>
            </Alert>
        } else {
            return <Card>
                <Card.Body>
                    <Card.Title><i className="fa-solid fa-calendar-day"/> Оплата сегодня</Card.Title>
                    <ListGroup>
                        {
                            clients.map(c => <ClientMiniCard client={c}/>)
                        }
                    </ListGroup>
                    <br/>
                    <Button style={{width: "100%"}} onClick={() => navigate("/clients?filterEnable=true&payDate=today")}><i
                        className="fa-solid fa-list"/> Открыть
                        список</Button>
                </Card.Body>
            </Card>
        }
    };

    const CardClientsTomorrow = () => {
        const [loadResult, setLoadResult] = useState("loading");
        const [clients, setClients] = useState([]);

        const getData = () => {
            axios.get("/api/clients", {headers: {token}})
                .then(data => {
                    if (data.status === 403) {
                        setLoadResult("notAccess");
                    } else if (data.data === []) {
                        setLoadResult("notFound");
                    } else {
                        setClients(data.data.filter(c => moment(c.lastPay).add(-1, "d").isSame(moment(), "d") && c.placeId).sort((a, b) => {
                            let dateA = new Date(a.lastPay);
                            let dateB = new Date(b.lastPay);

                            return dateA - dateB;
                        }));
                        setLoadResult("ok");
                    }
                })
        };

        useEffect(() => {
            if (loadResult === "loading") {
                getData();
            }
        }, [loadResult]);

        if (loadResult === "loading") {
            return <Spinner/>
        } else if ((clients.length === 0 && loadResult === "ok") || loadResult === "notFound") {
            return <Alert variant="success">
                <Alert.Heading><i className="fa-solid fa-check"/> Оплат на сегодня нет</Alert.Heading>
            </Alert>
        } else {
            return <Card>
                <Card.Body>
                    <Card.Title><i className="fa-solid fa-calendar-day"/> Оплата завтра</Card.Title>
                    <ListGroup>
                    {
                        clients.map(c => <ClientMiniCard client={c}/>)
                    }
                    </ListGroup>
                    <br/>
                    <Button onClick={() => navigate("/clients?filterEnable=true&payDate=tomorrow")} style={{width: "100%"}}><i
                        className="fa-solid fa-list"/> Открыть
                        список</Button>
                </Card.Body>
            </Card>
        }
    };

    const CardClientsTomorrowLegalEntity = () => {
        const [loadResult, setLoadResult] = useState("loading");
        const [clients, setClients] = useState([]);

        const getData = () => {
            axios.get("/api/clients", {headers: {token}})
                .then(data => {
                    if (data.status === 403) {
                        setLoadResult("notAccess");
                    } else if (data.data === []) {
                        setLoadResult("notFound");
                    } else {
                        setClients(data.data.filter(c => !moment(c.lastPay).add(-7, "d").isAfter(moment(), "d") && c.legalEntityEnable && c.placeId && !moment(c.lastPay).add(-1, "d").isSame(moment(), "d") && !moment(c.lastPay).isSame(moment(), "d") && !moment(c.lastPay).isBefore(moment(), "d")).sort((a, b) => {
                            let dateA = new Date(a.lastPay);
                            let dateB = new Date(b.lastPay);

                            return dateA - dateB;
                        }));
                        setLoadResult("ok");
                    }
                })
        };

        useEffect(() => {
            if (loadResult === "loading") {
                getData();
            }
        }, [loadResult]);

        if (loadResult === "loading") {
            return <Spinner/>
        } else if ((clients.length === 0 && loadResult === "ok") || loadResult === "notFound") {
            return <Alert variant="success">
                <Alert.Heading><i className="fa-solid fa-check"/> Оплат на сегодня нет</Alert.Heading>
                <p>Тут будут отображаться отплаты ваших контрагентов на близжайшие 7 дней (послезавтрешнего дня)</p>
            </Alert>
        } else {
            return <Card>
                <Card.Body>
                    <Card.Title><i className="fa-solid fa-calendar-week"/> Оплаты контрагентов на близжайшие 7 дней</Card.Title>
                    <ListGroup>
                        {
                            clients.map(c => <ClientMiniCard client={c}/>)
                        }
                    </ListGroup>
                    <br/>
                    <Button onClick={() => navigate("/clients?filterEnable=true&payDate=tomorrowLegalEntity")}
                            style={{width: "100%"}}><i className="fa-solid fa-list"/> Открыть список</Button>
                </Card.Body>
            </Card>
        }
    };

    return <>
        <ResponsiveMasonry
            columnsCountBreakPoints={{350: 1, 600: 2, 900: 3}}
            style={{padding: "10px", width: "calc(100%)"}}
        >
            <Masonry gutter="10px">
                <CardRoomsFree/>
                <CardClientsDelay/>
                <CardClientsToday/>
                <CardClientsTomorrow/>
                <CardClientsTomorrowLegalEntity/>
            </Masonry>
        </ResponsiveMasonry>
    </>
};