import React from "react";
import {Menu, MenuDivider, MenuItem} from "@blueprintjs/core";
import {useNavigate} from "react-router";

export const MenuPage = () => {
    const navigate = useNavigate();

    return <>
        <Menu large>
            <MenuItem icon="people" onClick={() => navigate("/clients")}
                      text="Клиенты"/>
            <MenuItem icon="home" onClick={() => navigate("/places")}
                      text="Койко-места"/>
            <MenuItem icon="home" onClick={() => navigate("/rooms")}
                      text="Комнаты"/>
            <MenuItem icon="home" onClick={() => navigate("/hotels")}
                      text="Отели"/>
            <MenuDivider/>
            <MenuItem icon="history" onClick={() => navigate("/history")}
                      text="Операции"/>
            <MenuItem icon="panel-stats" onClick={() => navigate("/stats")}
                      text="Статистика"/>
            <MenuDivider/>
            <MenuItem icon="cog" onClick={() => navigate("/settings")}
                      text="Настройки"/>
        </Menu>
    </>
};