import React, {useEffect, useState} from "react";
import {Center} from "react-containers";
import {Button, Callout, Card, FormGroup, InputGroup, Tooltip} from "@blueprintjs/core";
import {Intent} from "@blueprintjs/core/lib/esnext/common/intent";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
import axios from "axios";

export const LoginPage = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [nickName, setNickName] = useState("");
    const [password, setPassword] = useState("");
    const [sendResult, setSendResult] = useState("disabled");
    const {isAuth} = useSelector(state => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuth) navigate("/");
    }, []);

    const send = () => {
        setSendResult("loading");
        axios.post("/api/auth/login", {
            nickName, password,
        }).then(data => {
            if (data.data === false || data.status === 403) {
                setSendResult("error");
            } else {
                localStorage.setItem("token", data.data.token);
                setSendResult("");
                window.location.reload();
            }
        });
    };

    const lockButton = () => {
        return <Tooltip content={`${showPassword ? "Скрыть" : "Показать"} пароль`}>
            <Button
                icon={showPassword ? "unlock" : "lock"}
                intent={Intent.WARNING}
                minimal={true}
                onClick={() => setShowPassword(!showPassword)}
            />
        </Tooltip>
    };

    const checkDisable = () => {
        if (sendResult === "error") {
            setSendResult("");
        }
        if (!nickName || !password) {
            setSendResult("disabled");
        } else {
            setSendResult("");
        }
    };

    return <div style={{width: "100vw", height: "100vh"}}>
        <Center>
            <Card className="centeredCard">
                <h3>Вход в OptiBiz:Отель</h3>
                <FormGroup
                    label="Логин"
                    labelFor="login-input"
                >
                    <InputGroup
                        id="login-input"
                        leftIcon="person"
                        onChange={(e) => {
                            setNickName(e.target.value);
                            checkDisable();
                        }}
                    />
                </FormGroup>
                <FormGroup
                    label="Пароль"
                    labelFor="password-input"
                >
                    <InputGroup
                        id="password-input"
                        leftIcon="key"
                        rightElement={lockButton()}
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            checkDisable();
                        }}
                    />
                </FormGroup>
                <Button onClick={send} icon="log-in" fill intent={Intent.PRIMARY} loading={sendResult === "loading"}>Войти</Button>
                {
                    sendResult === "error"
                        ? <><br/><Callout intent={Intent.DANGER} title="Ошибка">
                            <p>Вы ввели не верный логин или пароль. Повторите попытку или обратитесь к специалисту.</p>
                        </Callout></>
                        : null
                }
            </Card>
        </Center>
    </div>
};