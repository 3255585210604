import React, {useEffect, useState} from "react";
import {Intent, Spinner} from "@blueprintjs/core";
import axios from "axios";
import {useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate} from "react-router";
import {useMediaQuery} from "react-responsive";
import moment from "moment";
import {Button, ButtonGroup, Card, ListGroup} from "react-bootstrap";
import Icon from "@mdi/react";
import {
    mdiAccount,
    mdiAccountCash,
    mdiAccountPlus, mdiDelete,
    mdiFilter,
    mdiFilterMinus,
    mdiInformationVariant,
    mdiListBox,
    mdiPlus,
    mdiReload
} from "@mdi/js";

export const PlacesPage = () => {
    const [places, setPlaces] = useState([{
        no: "",
        hotel: {
            name: "",
        },
        room: {
            no: 0,
        },
        type: "",
    }]);
    const [loadingResult, setLoadingResult] = useState("loading");
    const {token} = useSelector(state => state.auth);
    const navigate = useNavigate();

    const getData = () => {
        axios.get("/api/places", {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadingResult("notAccess");
                } else if (data.data === []) {
                    setLoadingResult("notFound");
                } else {
                    setPlaces(data.data.sort((a, b) => {
                        if (a.no > b.no) {
                            return 1;
                        } else if (a.no < b.no) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }).sort((a, b) => {
                        if (a.room.hotel.id > b.room.hotel.id) {
                            return 1;
                        } else if (a.room.hotel.id < b.room.hotel.id) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }));
                    setLoadingResult("ok");
                }
            })
    };

    const deleteData = (id) => {
        axios.delete("/api/places/" + id, {headers: {token}})
            .then(() => {
                setLoadingResult("update");
            })
    };

    useEffect(() => {
        if (loadingResult === "loading" || loadingResult === "update") {
            getData();
        }
    }, [loadingResult]);

    const isMobile = useMediaQuery({
        query: "(max-width: 600px)",
    });

    return <div style={{padding: "10px"}}>
        {
            !isMobile
                ? <ButtonGroup>
                    <Button onClick={() => navigate("/places/add")}><Icon path={mdiPlus} size={1}/> Новое
                        койко-место</Button>
                    <Button onClick={() => setLoadingResult("update")}
                            loading={loadingResult === "update"}><Icon path={mdiReload} size={1}/> Обновить</Button>
                </ButtonGroup>
                : <ButtonGroup>
                    <Button onClick={() => navigate("/places/add")}><Icon path={mdiPlus} size={1}/></Button>
                    <Button onClick={() => setLoadingResult("update")}
                            loading={loadingResult === "update"}><Icon path={mdiReload} size={1}/></Button>
                </ButtonGroup>
        }
        <br/><br/>
        {
            loadingResult === "ok"
                ? !isMobile ? <ListGroup>
                    {
                        places.map(r => {
                            return <ListGroup.Item style={{background: !r.client ? "#aaaaaa" : null}}>
                                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                    <div>
                                        <span>{r.room.hotel.shortName}/{r.room.no}/{r.no}</span> <span
                                        className="text-muted">{r.room.hotel.name}</span>
                                    </div>
                                    <div>
                                        <ButtonGroup size="sm">
                                            <Button variant="outline-secondary" onClick={() => navigate("/places/" + r.id)}><Icon path={mdiListBox}
                                                                                                      color="black"
                                                                                                      size={1}/></Button>
                                            <Button variant="outline-secondary" onClick={() => navigate("/places/" + r.id + "/edit")}><Icon
                                                path={mdiInformationVariant} size={1} color="black"/></Button>
                                            <Button variant="outline-secondary"><Icon path={mdiAccount} size={1} color="black"/></Button>
                                            <Button variant="outline-danger" onClick={() => deleteData(r.id)} intent={Intent.DANGER}><Icon
                                                path={mdiDelete} color="black" size={1}/></Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                                {
                                    r.client
                                        ? <>
                                            <br/>
                                            <Card>
                                                <Card.Body>
                                                    <span><span>{r.client.fullNo} {r.client.fullName}</span> <span
                                                        className="text-muted">{moment(r.client.lastPay).format("DD/MM ddd")}</span></span>
                                                </Card.Body>
                                            </Card>
                                        </>
                                        : null
                                }
                            </ListGroup.Item>
                        })
                    }
                </ListGroup> : <ListGroup>
                    {
                        places.map(r => {
                            return <ListGroup.Item style={{background: !r.client ? "#aaaaaa" : null}}>
                                <span>{r.room.hotel.shortName}/{r.room.no}/{r.no}</span> <span className="text-muted">{r.room.hotel.name}</span>
                                {
                                    r.client
                                        ? <>
                                            <br/>
                                            <Card>
                                                <Card.Body>
                                                    <span><span>{r.client.fullNo} {r.client.fullName}</span> <span
                                                        className="text-muted">{moment(r.client.lastPay).format("DD/MM ddd")}</span></span>
                                                </Card.Body>
                                            </Card>
                                        </>
                                        : null
                                }
                                <ButtonGroup size="sm" style={{margin: 5}}>
                                    <Button variant="outline-secondary" onClick={() => navigate("/places/" + r.id)}><Icon path={mdiListBox}
                                                                                                                          color="black"
                                                                                                                          size={1}/></Button>
                                    <Button variant="outline-secondary" onClick={() => navigate("/places/" + r.id + "/edit")}><Icon
                                        path={mdiInformationVariant} size={1} color="black"/></Button>
                                    <Button variant="outline-secondary"><Icon path={mdiAccount} size={1} color="black"/></Button>
                                    <Button variant="outline-danger" onClick={() => deleteData(r.id)} intent={Intent.DANGER}><Icon
                                        path={mdiDelete} color="black" size={1}/></Button>
                                </ButtonGroup>
                            </ListGroup.Item>
                        })
                    }
                </ListGroup>
                : <Spinner size={100}/>
        }
    </div>
};