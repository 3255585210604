import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, FormGroup, InputGroup, MenuItem, Spinner, Switch, TextArea} from "@blueprintjs/core";
import axios from "axios";
import {useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate} from "react-router";
import {Select} from "@blueprintjs/select";

export const AddPlacePage = () => {
    const [place, setPlace] = useState({
        no: "",
        roomId: 1,
    });
    const [rooms, setRooms] = useState([{
        id: 1,
        no: 0,
        hotel: {
            shortName: "",
        },
    }]);
    const [sendResult, setSendResult] = useState("");
    const [loadResult, setLoadResult] = useState("loading");
    const {token} = useSelector(state => state.auth);
    const navigate = useNavigate();

    const send = () => {
        axios.post("/api/places", {
            no: place.no,
            hotelId: (rooms.find(r => r.id === place.roomId)).hotelId,
            roomId: place.roomId,
        }, {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setSendResult("notAccess");
                } else {
                    navigate("/places");
                }
            })
    };

    const getData = () => {
        axios.get("/api/rooms", {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadResult("notAccess");
                } else if (data.data === []) {
                    setLoadResult("notFound");
                } else {
                    setRooms(data.data);
                    setPlace({
                        ...place,
                        roomId: data.data[0].id,
                    });
                    setLoadResult("ok");
                }
            })
    };

    console.log(rooms);

    useEffect(() => {
        if (loadResult === "loading") {
            getData();
        }
    }, [loadResult]);

    return <div style={{padding: "10px"}}>
        {
            loadResult === "loading"
                ? <Spinner size={100}/>
                : null
        }
        {
            loadResult === "ok"
                ? <>
                    <h2>Добавление койко-места</h2>
                    <div style={{display: "flex", gap: "10px"}}>
                        <FormGroup
                            label="Номер койко-места"
                            labelFor="no-input"
                        >
                            <InputGroup
                                id="no-input"
                                type="number"
                                onChange={(e) => {
                                    setPlace({
                                        ...place,
                                        no: e.target.value,
                                    })
                                }}
                            />
                        </FormGroup>
                        <FormGroup
                            label="Отель"
                            labelFor="type-input"
                        >
                            <Select
                                items={rooms}
                                itemRenderer={(item) => {
                                    return <MenuItem text={`${item.hotel.shortName}/${item.no}`} onClick={() => setPlace({
                                        ...place,
                                        roomId: item.id,
                                    })}/>
                                }}
                                filterable={false}
                            >
                                <Button
                                    rightIcon="double-caret-vertical">{(rooms.find(r => r.id === place.roomId)).hotel.shortName}/{(rooms.find(r => r.id === place.roomId)).no}</Button>
                            </Select>
                        </FormGroup>
                    </div>
                    <Button onClick={send} loading={sendResult === "loading"}>Сохранить</Button>
                </>
                : null
        }
    </div>
};