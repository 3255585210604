import React, {useEffect, useState} from "react";
import {
    Button,
    ButtonGroup, Card, CardList,
    Dialog,
    DialogBody,
    DialogFooter,
    FormGroup,
    InputGroup,
    Intent,
    Spinner
} from "@blueprintjs/core";
import axios from "axios";
import {useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate} from "react-router";
import moment from "moment";
import QRCode from "react-qr-code";
import {useMediaQuery} from "react-responsive";

export const UsersPage = () => {
    const [users, setUsers] = useState([{}]);
    const [loadingResult, setLoadingResult] = useState("loading");
    const {token} = useSelector(state => state.auth);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState({
        title: "",
        content: <></>,
    });
    const navigate = useNavigate();

    const isMobile = useMediaQuery({
        query: "(max-width: 600px)",
    });

    const handleDialogOpen = (title, content) => {
        setDialogContent({title, content});
        setDialogIsOpen(true);
    };

    const getData = () => {
        axios.get("/api/users", {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadingResult("notAccess");
                } else if (data.data === []) {
                    setLoadingResult("notFound");
                } else {
                    setUsers(data.data);
                    setLoadingResult("ok");
                }
            })
    };

    const deleteData = (id) => {
        axios.delete("/api/users/" + id, {headers: {token}})
            .then(() => {
                setLoadingResult("update");
            })
    };

    useEffect(() => {
        if (loadingResult === "loading" || loadingResult === "update") {
            getData();
        }
    }, [loadingResult]);

    return <div style={{padding: "10px"}}>
        <ButtonGroup>
            <Button icon="plus" onClick={() => navigate("/settings/users/add")}>Добавить пользователя</Button>
            <Button icon="repeat" onClick={() => setLoadingResult("update")}
                    loading={loadingResult === "update"}>Обновить</Button>
        </ButtonGroup>
        <br/><br/>
        {
            loadingResult === "ok"
                ? !isMobile ? <table className="table" border="1" style={{borderStyle: "solid"}}>
                    <thead>
                    <tr>
                        <th width="80" rowSpan={2}>ID</th>
                        <th width="150" rowSpan={2}>Логин</th>
                        <th rowSpan={2}>Имя</th>
                        <th rowSpan={2}>Роль</th>
                        <th rowSpan={2}>Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        users.map(r => {
                            return <tr>
                                <td>{r.id}</td>
                                <td>{r.nickName}</td>
                                <td>{r.name}</td>
                                <td>{r.superUser ? "Супер-пользователь" : "Администратор"}</td>
                                <td>
                                    <Button icon="more" onClick={() => navigate("/settings/users/" + r.id)}/>
                                    <Button icon="edit" onClick={() => navigate("/settings/users/" + r.id + "/edit")}/>
                                    <Button icon="delete" onClick={() => deleteData(r.id)} intent={Intent.DANGER}/>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table> : <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {
                        users.map(u => {
                            return <Card style={{width: "100%"}}>
                                <b>{u.name}</b>
                                <p>{u.nickName}</p>
                                <p>{u.superUser ? "Супер-пользователь" : "Администратор"}</p>
                                <ButtonGroup>
                                    <Button icon="more"
                                            onClick={() => navigate('/settings/users/' + u.id)}>Подробнее</Button>
                                </ButtonGroup>
                            </Card>
                        })
                    }
                </div>
                : <Spinner size={100}/>
        }
        <Dialog isOpen={dialogIsOpen} onClose={() => setDialogIsOpen(false)} title={dialogContent.title}>
            {dialogContent.content}
        </Dialog>
    </div>
};