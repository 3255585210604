import React from "react";
import {Menu, MenuDivider, MenuItem} from "@blueprintjs/core";
import {useNavigate} from "react-router";

export const SettingsPage = () => {
    const navigate = useNavigate();

    return <>
        <Menu large>
            <MenuItem icon="cog" text="Платежные онлайн-терминалы" onClick={() => navigate("/settings/paymentTerminals")}/>
            <MenuDivider/>
            <MenuItem icon="cog" text="Пользователи" onClick={() => navigate("/settings/users")}/>
            <MenuItem icon="cog" text="О системе"/>
        </Menu>
    </>
};