import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, Card, Intent, Spinner} from "@blueprintjs/core";
import axios from "axios";
import {useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate} from "react-router";
import {useMediaQuery} from "react-responsive";
import moment from "moment";

export const HotelsPage = () => {
    const [hotels, setHotels] = useState([{
        name: "",
        shortName: "",
        type: "",
        priceDefault: {
            "1": 0,
            "7": 0,
            "30": 0,
        },
    }]);
    const [loadingResult, setLoadingResult] = useState("loading");
    const {token} = useSelector(state => state.auth);
    const navigate = useNavigate();

    const getData = () => {
        axios.get("/api/hotels", {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadingResult("notAccess");
                } else if (data.data === []) {
                    setLoadingResult("notFound");
                } else {
                    setHotels(data.data);
                    setLoadingResult("ok");
                }
            })
    };

    const getTypeStr = (type) => {
        switch (type) {
            case "hotel":
                return "Отель";
            case "hostel":
                return "Хостел";
            case "mixed":
                return "Смешанный";
        }
    };

    const isMobile = useMediaQuery({
        query: "(max-width: 600px)",
    });

    useEffect(() => {
        if (loadingResult === "loading" || loadingResult === "update") {
            getData();
        }
    }, [loadingResult]);

    return <div style={{padding: "10px"}}>
        <ButtonGroup>
            <Button icon="plus" onClick={() => navigate("/hotels/add")}>Новый отель</Button>
            <Button icon="repeat" onClick={() => setLoadingResult("update")}
                    loading={loadingResult === "update"}>Обновить</Button>
        </ButtonGroup>
        <br/><br/>
        {
            loadingResult === "ok"
                ? !isMobile ? <table className="table" border="1" style={{borderStyle: "solid"}}>
                    <thead>
                    <tr>
                        <th width="40" rowSpan={2}>ID</th>
                        <th width="40" rowSpan={2}>КН</th>
                        <th width="150" rowSpan={2}>Название</th>
                        <th width="80" rowSpan={2}>Тип</th>
                        <th rowSpan={1} colSpan={3} width="150">
                            Цены стандартные
                        </th>
                        <th rowSpan={2}>Загруженность</th>
                        <th rowSpan={2}>Действия</th>
                    </tr>
                    <tr>
                        <th width="70">Сутки</th>
                        <th width="70">Неделя</th>
                        <th width="70">Месяц</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        hotels.map(h => {
                            return <tr>
                                <td>{h.id}</td>
                                <td>{h.shortName}</td>
                                <td>{h.name}</td>
                                <td>{getTypeStr(h.type)}</td>
                                <td>{h.priceDefault["1"]} ₽</td>
                                <td>{h.priceDefault["7"]} ₽</td>
                                <td>{h.priceDefault["30"]} ₽</td>
                                <td>{h.countFullPlaces}/{h.countPlaces} ({((h.countFullPlaces / h.countPlaces) * 100).toFixed(2)}%)</td>
                                <td>
                                    <Button icon="more" onClick={() => navigate("/hotels/" + h.id)}/>
                                    <Button icon="edit" onClick={() => navigate("/hotels/" + h.id + "/edit")}/>
                                    <Button icon="people"/>
                                    <Button icon="delete" intent={Intent.DANGER}/>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table> : <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {
                        hotels.map(h => {
                            return <Card style={{width: "100%"}}>
                                <b>{h.shortName} {h.name}</b>
                                <p>{h.priceDefault["1"]} ₽ {h.priceDefault["7"]} ₽ {h.priceDefault["30"]} ₽</p>
                                <p>{h.countFullPlaces}/{h.countPlaces} ({((h.countFullPlaces / h.countPlaces) * 100).toFixed(2)}%)</p>
                                <ButtonGroup>
                                    <Button icon="more" onClick={() => navigate("/hotels/" + h.id)}>Подробнее</Button>
                                    <Button icon="people">Клиенты</Button>
                                </ButtonGroup>
                            </Card>
                        })
                    }
                </div>
                : <Spinner size={100}/>
        }
    </div>
};