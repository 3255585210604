import React, {useEffect, useState} from "react";
import {FormGroup, InputGroup, Intent, MenuItem, Spinner, TextArea} from "@blueprintjs/core";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate, useParams} from "react-router";
import {Select} from "@blueprintjs/select";
import moment from "moment";
import {PayClientModal} from "../../Components/Modals/Clients/PayClientModal";
import Icon from "@mdi/react";
import {mdiAccountCash} from "@mdi/js";
import {showModal, hideModal} from "../../Store/appSlice";
import {Button, ButtonGroup, Card, Container} from "react-bootstrap";
import MasonryResponsive from "react-responsive-masonry/lib/ResponsiveMasonry";
import Masonry from "react-responsive-masonry";
import {HistoryCard} from "../../Components/History/HistoryCards";

export const GetClientPage = () => {
    const [client, setClient] = useState({});
    const [loadResult, setLoadResult] = useState("loading");
    const {token} = useSelector(state => state.auth);
    const navigate = useNavigate();
    const {id} = useParams();
    const dispatch = useDispatch();

    const load = () => {
        axios.get("/api/clients/" + id, {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadResult("notAccess");
                } else if (!data.data) {
                    setLoadResult("notFound");
                } else {
                    setLoadResult("");
                    setClient(data.data);
                }
            });
    };

    const deleteData = (id) => {
        axios.delete("/api/clients/" + id, {headers: {token}})
            .then(() => {
                navigate('/clients');
            })
    };

    useEffect(() => {
        if (loadResult === "loading") {
            load();
        }
    }, [loadResult]);

    return <Container>
        {
            loadResult !== "loading" && loadResult === ""
                ? <>
                    <h2>{client.name} {client.city}</h2>
                    <MasonryResponsive columnsCountBreakPoints={{350: 1, 600: 2, 900: 3}}>
                        <Masonry gutter="10px">
                            <Card>
                                <Card.Body>
                                    <Button style={{width: "100%", marginBottom: "10px"}} size="lg"
                                            onClick={() => dispatch(showModal({
                                                title: `Онлайн оплата ${client.fullNo} ${client.fullName}`,
                                                element: <PayClientModal onClose={() => dispatch(hideModal())}
                                                                         onUpdate={() => {
                                                                             setLoadResult("loading");
                                                                             dispatch(hideModal())
                                                                         }} id={client.id} client={client}/>
                                            }))}><i
                                        className="fa-solid fa-cash-register"/> Оплата</Button>
                                    <div style={{display: "flex", gap: "10px"}}>
                                        <Button style={{width: "50%"}}
                                                onClick={() => navigate("/clients/" + client.id + "/edit")}>
                                            <i className="fa-solid fa-pencil"/> Редактировать
                                        </Button>
                                        <Button variant="danger" style={{width: "50%"}}
                                                onClick={() => deleteData(client.id)} intent={Intent.DANGER}><i
                                            className="fa-solid fa-trash"/> Выселить</Button>
                                    </div>
                                    {
                                        client.contacts
                                            ? <div style={{display: "flex"}}>
                                                {client.contacts.phone ? <Button size="lg" className="optibiz-transparent-btn"
                                                                                 onClick={() => window.open(`tel:${client.contacts.phone}`, "_blank")}><i
                                                    className="fa-solid fa-phone"/></Button> : null}
                                                {client.contacts.whatsapp ? <Button size="lg" className="optibiz-transparent-btn"
                                                                                    onClick={() => window.open(`https://wa.me/${client.contacts.whatsapp.replace("+", "")}`, "_blank")}><i
                                                    className="fa-brands fa-whatsapp"/></Button> : null}
                                            </div>
                                            : null
                                    }
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Основная информация
                                    </Card.Title>
                                    <table className="info">
                                        <tbody>
                                        <tr>
                                            <td>ID</td>
                                            <td>{client.id}</td>
                                        </tr>
                                        <tr>
                                            <td>Койко-место</td>
                                            <td>{client.place.room.hotel.shortName}/{client.place.room.no}/{client.place.no}</td>
                                        </tr>
                                        <tr>
                                            <td>Отель</td>
                                            <td>{client.place.room.hotel.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Дата оплаты</td>
                                            <td>{moment(client.lastPay).format("DD/MM ddd")}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Дополнительная информация
                                    </Card.Title>
                                    <table className="info">
                                        <tr>
                                            <td>Создан</td>
                                            <td>{moment(client.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                                        </tr>
                                    </table>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Последние операции
                                    </Card.Title>
                                    {
                                        client.clientLog.toReversed().map((log, i) => {
                                            if (i < 20) {
                                                return <HistoryCard log={log} mini/>
                                            }
                                        })
                                    }
                                </Card.Body>
                            </Card>
                        </Masonry>
                    </MasonryResponsive>
                </>
                : <Spinner size={100}/>
        }
    </Container>
};