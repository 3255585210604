import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, FormGroup, InputGroup, MenuItem, Spinner, Switch, TextArea} from "@blueprintjs/core";
import axios from "axios";
import {useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate} from "react-router";
import {Select} from "@blueprintjs/select";

export const AddRoomPage = () => {
    const [room, setRoom] = useState({
        no: "",
        hotelId: 1,
        type: "person",
        priceCustomPolicyEnable: false,
        priceCustomPolicy: {
            "1": 0,
            "7": 0,
            "30": 0,
        },
    });
    const [hotels, setHotels] = useState([{
        id: 0,
        name: "",
    }]);
    const [sendResult, setSendResult] = useState("");
    const [loadResult, setLoadResult] = useState("loading");
    const {token} = useSelector(state => state.auth);
    const navigate = useNavigate();

    const send = () => {
        axios.post("/api/rooms", {
            no: room.no,
            hotelId: room.hotelId,
            priceCustomPolicyEnable: room.priceCustomPolicyEnable,
            priceCustomPolicy: room.priceCustomPolicy,
            type: room.type,
        }, {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setSendResult("notAccess");
                } else {
                    navigate("/hotels");
                }
            })
    };

    const getData = () => {
        axios.get("/api/hotels", {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadResult("notAccess");
                } else if (data.data === []) {
                    setLoadResult("notFound");
                } else {
                    setHotels(data.data);
                    setLoadResult("ok");
                }
            })
    };

    useEffect(() => {
        if (loadResult === "loading") {
            getData();
        }
    }, [loadResult]);

    const types = [
        {title: "Персональный", type: "person"},
        {title: "Хостел", type: "hostel"},
    ];

    return <div style={{padding: "10px"}}>
        {
            loadResult === "loading"
                ? <Spinner size={100}/>
                : null
        }
        {
            loadResult === "ok"
                ? <>
                    <h2>Добавление комнаты</h2>
                    <div style={{display: "flex", gap: "10px"}}>
                        <FormGroup
                            label="Номер комнаты"
                            labelFor="no-input"
                        >
                            <InputGroup
                                id="no-input"
                                type="number"
                                onChange={(e) => {
                                    setRoom({
                                        ...room,
                                        no: e.target.value,
                                    })
                                }}
                            />
                        </FormGroup>
                        <FormGroup
                            label="Тип комнаты"
                            labelFor="type-input"
                        >
                            <Select
                                items={types}
                                itemRenderer={(item) => {
                                    return <MenuItem text={item.title} onClick={() => setRoom({
                                        ...room,
                                        type: item.type,
                                    })}/>
                                }}
                                filterable={false}
                            >
                                <Button
                                    rightIcon="double-caret-vertical">{(types.find(type => type.type === room.type)).title}</Button>
                            </Select>
                        </FormGroup>
                        <FormGroup
                            label="Отель"
                            labelFor="type-input"
                        >
                            <Select
                                items={hotels}
                                itemRenderer={(item) => {
                                    return <MenuItem text={item.name} onClick={() => setRoom({
                                        ...room,
                                        hotelId: item.id,
                                    })}/>
                                }}
                                filterable={false}
                            >
                                <Button
                                    rightIcon="double-caret-vertical">{(hotels.find(h => h.id === room.hotelId)).name}</Button>
                            </Select>
                        </FormGroup>
                    </div>
                    <Switch checked={room.priceCustomPolicyEnable} onChange={(e) => setRoom({
                        ...room,
                        priceCustomPolicyEnable: e.target.checked,
                    })} labelElement="Индивидуальная ценовая политика" />
                    {
                        room.priceCustomPolicyEnable
                            ? <>
                                <h4>Цены</h4>
                                <div style={{display: "flex", gap: "10px"}}>
                                    <FormGroup
                                        label="Сутки"
                                        labelFor="price1-input"
                                    >
                                        <InputGroup
                                            id="price1-input"
                                            onChange={(e) => {
                                                setRoom({
                                                    ...room,
                                                    priceCustomPolicy: {
                                                        ...room.priceCustomPolicy,
                                                        "1": e.target.value,
                                                    },
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        label="Неделя"
                                        labelFor="price7-input"
                                    >
                                        <InputGroup
                                            id="price7-input"
                                            onChange={(e) => {
                                                setRoom({
                                                    ...room,
                                                    priceCustomPolicy: {
                                                        ...room.priceCustomPolicy,
                                                        "7": e.target.value,
                                                    },
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        label="Месяц"
                                        labelFor="price30-input"
                                    >
                                        <InputGroup
                                            id="price30-input"
                                            onChange={(e) => {
                                                setRoom({
                                                    ...room,
                                                    priceCustomPolicy: {
                                                        ...room.priceCustomPolicy,
                                                        "30": e.target.value,
                                                    },
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                            </>
                            : null
                    }
                    <Button onClick={send} loading={sendResult === "loading"}>Сохранить</Button>
                </>
                : null
        }
    </div>
};