import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, FormGroup, InputGroup, MenuItem, TextArea} from "@blueprintjs/core";
import axios from "axios";
import {useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate} from "react-router";
import {Select} from "@blueprintjs/select";

export const AddHotelPage = () => {
    const [hotel, setHotel] = useState({
        name: "",
        shortName: "",
        type: "hotel",
        priceDefault: {
            "1": 0,
            "7": 0,
            "30": 0,
        },
        address: "",
    });
    const [sendResult, setSendResult] = useState("");
    const {token} = useSelector(state => state.auth);
    const navigate = useNavigate();

    console.log(hotel);

    const send = () => {
        axios.post("/api/hotels", {
            name: hotel.name,
            shortName: hotel.shortName,
            priceDefault: hotel.priceDefault,
            address: hotel.address,
            type: hotel.type,
        }, {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setSendResult("notAccess");
                } else {
                    navigate("/hotels");
                }
            })
    };

    const types = [
        {title: "Отель", type: "hotel"},
        {title: "Хостел", type: "hostel"},
        {title: "Смешанный", type: "mixed"},
    ];

    return <div style={{padding: "10px"}}>
        <h2>Добавление отеля</h2>
        <div style={{display: "flex", gap: "10px"}}>
            <FormGroup
                label="Название отеля"
                labelFor="name-input"
            >
                <InputGroup
                    id="name-input"
                    onChange={(e) => {
                        setHotel({
                            ...hotel,
                            name: e.target.value,
                        })
                    }}
                />
            </FormGroup>
            <FormGroup
                label="Краткое название хостела"
                labelFor="shortName-input"
            >
                <InputGroup
                    id="shortName-input"
                    onChange={(e) => {
                        setHotel({
                            ...hotel,
                            shortName: e.target.value,
                        })
                    }}
                />
            </FormGroup>
            <FormGroup
                label="Тип хостела"
                labelFor="type-input"
            >
                <Select
                    items={types}
                    itemRenderer={(item) => {
                        return <MenuItem text={item.title} onClick={() => setHotel({
                            ...hotel,
                            type: item.type,
                        })}/>
                    }}
                    filterable={false}
                >
                    <Button
                        rightIcon="double-caret-vertical">{(types.find(type => type.type === hotel.type)).title}</Button>
                </Select>
            </FormGroup>
        </div>
        <h4>Цены</h4>
        <div style={{display: "flex", gap: "10px"}}>
            <FormGroup
                label="Сутки"
                labelFor="price1-input"
            >
                <InputGroup
                    id="price1-input"
                    onChange={(e) => {
                        setHotel({
                            ...hotel,
                            priceDefault: {
                                ...hotel.priceDefault,
                                "1": e.target.value,
                            },
                        })
                    }}
                />
            </FormGroup>
            <FormGroup
                label="Неделя"
                labelFor="price7-input"
            >
                <InputGroup
                    id="price7-input"
                    onChange={(e) => {
                        setHotel({
                            ...hotel,
                            priceDefault: {
                                ...hotel.priceDefault,
                                "7": e.target.value,
                            },
                        })
                    }}
                />
            </FormGroup>
            <FormGroup
                label="Месяц"
                labelFor="price30-input"
            >
                <InputGroup
                    id="price30-input"
                    onChange={(e) => {
                        setHotel({
                            ...hotel,
                            priceDefault: {
                                ...hotel.priceDefault,
                                "30": e.target.value,
                            },
                        })
                    }}
                />
            </FormGroup>
        </div>
        <h4>Дополнительная информация</h4>
        <div style={{display: "flex", gap: "10px"}}>
            <FormGroup
                label="Адрес"
                labelFor="address-input"
            >
                <TextArea
                    id="address-input"
                    onChange={(e) => {
                        setHotel({
                            ...hotel,
                            address: e.target.value,
                        })
                    }}
                    value={hotel.address}
                />
            </FormGroup>
        </div>
        <Button onClick={send} loading={sendResult === "loading"}>Сохранить</Button>
    </div>
};