import React, {useEffect, useState} from "react";
import {Intent, Spinner} from "@blueprintjs/core";
import axios from "axios";
import {useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate} from "react-router";
import {useMediaQuery} from "react-responsive";
import {Button, ButtonGroup, Card, ListGroup} from "react-bootstrap";
import Icon from "@mdi/react";
import {mdiAccount, mdiBed, mdiDelete, mdiInformationVariant, mdiListBox, mdiPlus, mdiReload} from "@mdi/js";

export const RoomsPage = () => {
    const [rooms, setRooms] = useState([{
        no: "",
        hotel: {
            name: "",
        },
        type: "",
        priceCustomPolicyEnable: false,
        priceCustomPolicy: {
            "1": 0,
            "7": 0,
            "30": 0,
        },
    }]);
    const [loadingResult, setLoadingResult] = useState("loading");
    const {token} = useSelector(state => state.auth);
    const navigate = useNavigate();

    const getData = () => {
        axios.get("/api/rooms", {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadingResult("notAccess");
                } else if (data.data === []) {
                    setLoadingResult("notFound");
                } else {
                    setRooms(data.data);
                    setLoadingResult("ok");
                }
            })
    };

    const getTypeStr = (type) => {
        switch (type) {
            case "person":
                return "Персональный";
            case "hostel":
                return "Хостел";
        }
    };

    useEffect(() => {
        if (loadingResult === "loading" || loadingResult === "update") {
            getData();
        }
    }, [loadingResult]);

    const isMobile = useMediaQuery({
        query: "(max-width: 600px)",
    });

    return <div style={{padding: "10px"}}>
        {
            !isMobile
                ? <ButtonGroup>
                    <Button onClick={() => navigate("/rooms/add")}><Icon path={mdiPlus} size={1}/> Новая комната</Button>
                    <Button onClick={() => setLoadingResult("update")}
                            loading={loadingResult === "update"}><Icon path={mdiReload} size={1}/> Обновить</Button>
                </ButtonGroup>
                : <ButtonGroup>
                    <Button onClick={() => navigate("/rooms/add")}><Icon path={mdiPlus} size={1}/></Button>
                    <Button onClick={() => setLoadingResult("update")}
                            loading={loadingResult === "update"}><Icon path={mdiReload} size={1}/></Button>
                </ButtonGroup>
        }
        <br/><br/>
        {
            loadingResult === "ok"
                ? !isMobile ? <ListGroup>
                    {
                        rooms.map(r => {
                            return <ListGroup.Item>
                                <div>
                                    <span>{r.hotel.shortName}/{r.no}</span> <span
                                    className="text-muted">{r.hotel.name}</span>
                                </div>
                                {
                                    r.priceCustomPolicyEnable
                                        ? <>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>Индивидуальные цены</Card.Title>
                                                    <table>
                                                        <th>Сутки</th>
                                                        <th>Неделя</th>
                                                        <th>Месяц</th>
                                                        <tr>
                                                            <td>{r.priceCustomPolicy["1"] + "₽"}</td>
                                                            <td>{r.priceCustomPolicy["7"] + "₽"}</td>
                                                            <td>{r.priceCustomPolicy["30"] + "₽"}</td>
                                                        </tr>
                                                    </table>
                                                </Card.Body>
                                            </Card>
                                        </>
                                        : null
                                }
                                <span>Занято: {r.countFullPlaces}/{r.countPlaces} ({((r.countFullPlaces / r.countPlaces) * 100).toFixed(2)}%)</span>
                                <br/>
                                <ButtonGroup>
                                    <Button variant="outline-secondary" onClick={() => navigate("/rooms/" + r.id)}><Icon path={mdiListBox}
                                                                                             color="black"
                                                                                             size={1}/></Button>
                                    <Button variant="outline-secondary" onClick={() => navigate("/rooms/" + r.id + "/edit")}><Icon
                                        path={mdiInformationVariant} size={1} color="black"/></Button>
                                    <Button variant="outline-secondary" onClick={() => navigate("/clients?filterEnable=true&room=" + r.id)}><Icon path={mdiBed} size={1} color="black"/></Button>
                                    <Button variant="outline-danger"><Icon
                                        path={mdiDelete} color="black" size={1}/></Button>
                                </ButtonGroup>
                            </ListGroup.Item>
                        })
                    }
                </ListGroup> : <ListGroup>
                    {
                        rooms.map(r => {
                            return <ListGroup.Item>
                                <div>
                                    <span>{r.hotel.shortName}/{r.no}</span> <span
                                    className="text-muted">{r.hotel.name}</span>
                                </div>
                                {
                                    r.priceCustomPolicyEnable
                                        ? <>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title>Индивидуальные цены</Card.Title>
                                                    <table>
                                                        <th>Сутки</th>
                                                        <th>Неделя</th>
                                                        <th>Месяц</th>
                                                        <tr>
                                                            <td>{r.priceCustomPolicy["1"] + "₽"}</td>
                                                            <td>{r.priceCustomPolicy["7"] + "₽"}</td>
                                                            <td>{r.priceCustomPolicy["30"] + "₽"}</td>
                                                        </tr>
                                                    </table>
                                                </Card.Body>
                                            </Card>
                                        </>
                                        : null
                                }
                                <span>Занято: {r.countFullPlaces}/{r.countPlaces} ({((r.countFullPlaces / r.countPlaces) * 100).toFixed(2)}%)</span>
                                <br/>
                                <ButtonGroup>
                                    <Button variant="outline-secondary" onClick={() => navigate("/rooms/" + r.id)}><Icon path={mdiListBox}
                                                                                                                         color="black"
                                                                                                                         size={1}/></Button>
                                    <Button variant="outline-secondary" onClick={() => navigate("/rooms/" + r.id + "/edit")}><Icon
                                        path={mdiInformationVariant} size={1} color="black"/></Button>
                                    <Button variant="outline-secondary" onClick={() => navigate("/clients?filterEnable=true&room=" + r.id)}><Icon path={mdiBed} size={1} color="black"/></Button>
                                    <Button variant="outline-danger"><Icon
                                        path={mdiDelete} color="black" size={1}/></Button>
                                </ButtonGroup>
                            </ListGroup.Item>
                        })
                    }
                </ListGroup>
                : <Spinner size={100}/>
        }
    </div>
};