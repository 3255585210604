import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, FormGroup, InputGroup, MenuItem, Spinner, TextArea} from "@blueprintjs/core";
import axios from "axios";
import {useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate, useParams} from "react-router";
import {Select} from "@blueprintjs/select";

export const EditHotelPage = () => {
    const [hotel, setHotel] = useState({
        name: "",
        shortName: "",
        type: "hotel",
        priceDefault: {
            "1": 0,
            "7": 0,
            "30": 0,
        },
        address: "",
        paymentTerminalId: 0,
    });
    const [paymentTerminals, setPaymentTerminals] = useState([{}]);
    const [loadResult, setLoadResult] = useState("loading");
    const [sendResult, setSendResult] = useState("");
    const {token} = useSelector(state => state.auth);
    const navigate = useNavigate();
    const {id} = useParams();

    const load = () => {
        axios.get("/api/hotels/" + id, {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadResult("notAccess");
                } else if (!data.data) {
                    setLoadResult("notFound");
                } else {
                    setHotel(data.data);
                    axios.get("/api/settings/paymentTerminals", {headers: {token}})
                        .then(data => {
                            setPaymentTerminals(data.data);
                            setLoadResult("");
                        });
                }
            });
    };

    useEffect(() => {
        if (loadResult === "loading") {
            load();
        }
    }, [loadResult]);

    const send = () => {
        axios.put("/api/hotels/" + id, {
            name: hotel.name,
            shortName: hotel.shortName,
            priceDefault: hotel.priceDefault,
            address: hotel.address,
            type: hotel.type,
            paymentTerminalId: hotel.paymentTerminalId,
        }, {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setSendResult("notAccess");
                } else {
                    navigate("/hotels");
                }
            })
    };

    const types = [
        {title: "Отель", type: "hotel"},
        {title: "Хостел", type: "hostel"},
        {title: "Смешанный", type: "mixed"},
    ];

    return <div style={{padding: "10px"}}>
        {
            loadResult !== "loading" && loadResult === ""
                ? <>
                    <h2>Добавление отеля</h2>
                    <div style={{display: "flex", gap: "10px"}}>
                        <FormGroup
                            label="Название отеля"
                            labelFor="name-input"
                        >
                            <InputGroup
                                id="name-input"
                                defaultValue={hotel.name}
                                onChange={(e) => {
                                    setHotel({
                                        ...hotel,
                                        name: e.target.value,
                                    })
                                }}
                            />
                        </FormGroup>
                        <FormGroup
                            label="Краткое название хостела"
                            labelFor="shortName-input"
                        >
                            <InputGroup
                                id="shortName-input"
                                defaultValue={hotel.shortName}
                                onChange={(e) => {
                                    setHotel({
                                        ...hotel,
                                        shortName: e.target.value,
                                    })
                                }}
                            />
                        </FormGroup>
                        <FormGroup
                            label="Тип хостела"
                            labelFor="type-input"
                        >
                            <Select
                                items={types}
                                itemRenderer={(item) => {
                                    return <MenuItem text={item.title} onClick={() => setHotel({
                                        ...hotel,
                                        type: item.type,
                                    })}/>
                                }}
                                filterable={false}
                            >
                                <Button
                                    rightIcon="double-caret-vertical">{(types.find(type => type.type === hotel.type)).title}</Button>
                            </Select>
                        </FormGroup>
                    </div>
                    <h4>Цены</h4>
                    <div style={{display: "flex", gap: "10px"}}>
                        <FormGroup
                            label="Сутки"
                            labelFor="price1-input"
                        >
                            <InputGroup
                                id="price1-input"
                                defaultValue={hotel.priceDefault["1"]}
                                onChange={(e) => {
                                    setHotel({
                                        ...hotel,
                                        priceDefault: {
                                            ...hotel.priceDefault,
                                            "1": e.target.value,
                                        },
                                    })
                                }}
                            />
                        </FormGroup>
                        <FormGroup
                            label="Неделя"
                            labelFor="price7-input"
                        >
                            <InputGroup
                                id="price7-input"
                                defaultValue={hotel.priceDefault["7"]}
                                onChange={(e) => {
                                    setHotel({
                                        ...hotel,
                                        priceDefault: {
                                            ...hotel.priceDefault,
                                            "7": e.target.value,
                                        },
                                    })
                                }}
                            />
                        </FormGroup>
                        <FormGroup
                            label="Месяц"
                            labelFor="price30-input"
                        >
                            <InputGroup
                                id="price30-input"
                                defaultValue={hotel.priceDefault["30"]}
                                onChange={(e) => {
                                    setHotel({
                                        ...hotel,
                                        priceDefault: {
                                            ...hotel.priceDefault,
                                            "30": e.target.value,
                                        },
                                    })
                                }}
                            />
                        </FormGroup>
                    </div>
                    <div style={{display: "flex", gap: "10px"}}>
                        <FormGroup
                            label="Онлайн терминал для оплтаты"
                            labelFor="payment-terminal-input"
                        >
                            <Select
                                items={paymentTerminals}
                                itemRenderer={(item) => {
                                    return <MenuItem text={`${item.name} (${item.provider})`} onClick={() => setHotel({
                                        ...hotel,
                                        paymentTerminalId: item.id,
                                    })}/>
                                }}
                                filterable={false}
                            >
                                <Button
                                    rightIcon="double-caret-vertical">{hotel.paymentTerminalId ? `${(paymentTerminals.find(terminal => terminal.id === hotel.paymentTerminalId)).name}} ${(paymentTerminals.find(terminal => terminal.id === hotel.paymentTerminalId)).provider}` : "NONE"}</Button>
                            </Select>
                        </FormGroup>
                    </div>
                    <h4>Дополнительная информация</h4>
                    <div style={{display: "flex", gap: "10px"}}>
                        <FormGroup
                            label="Адрес"
                            labelFor="address-input"
                        >
                            <TextArea
                                id="address-input"
                                onChange={(e) => {
                                    setHotel({
                                        ...hotel,
                                        address: e.target.value,
                                    })
                                }}
                                value={hotel.address}
                            />
                        </FormGroup>
                    </div>
                    <Button onClick={send} loading={sendResult === "loading"}>Сохранить</Button>
                </>
                : <Spinner size={100}/>
        }

    </div>
};