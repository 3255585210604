import React, {useState} from "react";
import {Drawer, DrawerSize, Menu, MenuDivider, MenuItem} from "@blueprintjs/core";
import {useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";
import {useLocation, useNavigate} from "react-router";
import {Outlet} from "react-router";
import {Button, Navbar, Container} from "react-bootstrap";
import Icon from "@mdi/react";
import {
    mdiAccount,
    mdiAccountGroup,
    mdiAlert,
    mdiApplication,
    mdiApps,
    mdiBell, mdiHistory,
    mdiMenu, mdiPlus,
    mdiViewDashboardOutline
} from "@mdi/js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const OutletPage = () => {
    const isMobile = useMediaQuery({
        query: "(max-width: 600px)",
    });
    const {userData} = useSelector(state => state.auth);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const navigate = useNavigate();
    const {pathname} = useLocation();

    const navigateDrawer = (link) => {
        setIsOpenDrawer(false);
        navigate(link);
    };

    return <div style={{width: "100vw", height: "100vh"}}>
        <Navbar fixed="top" expand="sm" className="optibiz-navbar">
            <div className="optibiz-navbar-container">
                <div className="optibiz-navbar-container-child">
                    <Navbar.Brand>
                        {
                            isMobile
                                ? "OB:Отель"
                                : "OptiBiz:Отель 0.40.0 alpha"
                        }
                    </Navbar.Brand>
                </div>
                <div>
                    <Button className="optibiz-navbar-menu-btn"><i className="fa-solid fa-user"/></Button>
                    <Button className="optibiz-navbar-menu-btn"><i className="fa-solid fa-bell"/></Button>
                    <Button className="optibiz-navbar-menu-btn"><i className="fa-solid fa-grip"/></Button>
                </div>
            </div>
        </Navbar>
        <div style={{height: "calc(100%)", width: "100%", paddingTop: "80px"}}>
            <Outlet/>
            <br/><br/>
        </div>
        <Navbar fixed="bottom" expand="sm" className="optibiz-navbar bottom">
            <div className="optibiz-navbar-container spaced">
                <Button className={`optibiz-navbar-menu-btn ${pathname === "/" ? " active" : ""}`}
                        onClick={() => navigate("/")}><i className="fa-solid fa-house"/></Button>
                <Button className={`optibiz-navbar-menu-btn ${pathname === "/clients" ? " active" : ""}`}
                        onClick={() => navigate("/clients")}><i className="fa-solid fa-users"/></Button>
                <Button onClick={() => navigate("/clients/add")}><i className="fa-solid fa-circle-plus"/></Button>
                <Button className={`optibiz-navbar-menu-btn ${pathname === "/history" ? " active" : ""}`}
                        onClick={() => navigate("/history")}><i className="fa-solid fa-clock-rotate-left"/></Button>
                <Button className={`optibiz-navbar-menu-btn ${pathname === "/menu" ? " active" : ""}`}
                        onClick={() => navigate("/menu")}><i className="fa-solid fa-bars"/></Button>
            </div>
        </Navbar>
    </div>
};