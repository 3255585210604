import Icon from "@mdi/react";
import moment from "moment";
import Badge from "react-bootstrap/Badge";
import React from "react";
import {mdiAccountCash, mdiAccountClock, mdiAccountCreditCard, mdiAccountMinus, mdiAccountPlus} from "@mdi/js";

const getCategoryStr = (category) => {
    switch (category) {
        case "payManual":
            return "Ручная оплата";
        case "pay":
            return "Онлайн оплата";
        case "add":
            return "Заселение";
        case "delay":
            return "Просрочка";
        case "remove":
            return "Выселение";
    }
};

const getCategoryIcon = category => {
    switch (category) {
        case "payManual":
            return "fa-solid fa-money-bill";
        case "pay":
            return "fa-solid fa-credit-card";
        case "add":
            return mdiAccountPlus;
        case "delay":
            return mdiAccountClock;
        case "remove":
            return mdiAccountMinus;
    }
};

const getCategoryColor = category => {
    switch (category) {
        case "payManual":
            return "success";
        case "pay":
            return "success";
        case "add":
            return "success";
        case "delay":
            return "danger";
        case "remove":
            return "danger";
    }
};

export const HistoryCard = props => {
    const {log, mini} = props;

    if (mini) {
        return <div className={`optibiz-history-item${mini ? " mini" : ""}`}>
            <div className={`icon ${getCategoryColor(log.category)}`}>
                <i className={getCategoryIcon(log.category)}/>
            </div>
            <div className="content">
                <p className="time">{moment(log.createdAt).format("HH:mm DD/MM ddd")} </p>
                <p className="bigText">{log.meta?.amount ? "+" + log.meta?.amount + " ₽" : ""}</p>
                {log.userId ? <Badge bg="secondary">Выполнил: {log.user.name}</Badge> : null}
            </div>
        </div>
    } else {
        return <div className="optibiz-history-item">
            <div className={`icon ${getCategoryColor(log.category)}`}>
                <i className={getCategoryIcon(log.category)}/>
            </div>
            <div className="content">
                <p className="title">{log.client.fullNo ? log.client.fullNo : ""} {log.client.fullName}</p>
                <p className="time">{moment(log.createdAt).format("HH:mm DD/MM ddd")} </p>
                <p className="bigText">{log.meta?.amount ? "+" + log.meta?.amount + " ₽" : ""}</p>
                {log.userId ? <Badge bg="secondary">Выполнил: {log.user.name}</Badge> : null}
            </div>
        </div>
    }
};