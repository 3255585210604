import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, FormGroup, InputGroup, MenuItem, Spinner, Switch, TextArea} from "@blueprintjs/core";
import axios from "axios";
import {useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate} from "react-router";
import {Select} from "@blueprintjs/select";

export const AddPaymentTerminalPage = () => {
    const [paymentTerminal, setPaymentTerminal] = useState({
        name: "",
        provider: 1,
        enableSendInOFD: false,
        enableTestTerminal: false,
        receiptConfig: {
            FFDVersion: "",
            taxation: "",
            enableTypeSend: ["email"],
            defaultNameItem: "",
            tax: "",
            paymentMethod: "",
            paymentObject: "",
            measurementUnit: "",
            redirectEmailForCancelSend: "",
        },
        terminal: {
            terminalId: "",
            keyFirst: "",
            keySecond: "",
        },
        terminalTest: {
            terminalId: "",
            keyFirst: "",
            keySecond: "",
        },
    });
    const [available, setAvailable] = useState([{}]);
    const [sendResult, setSendResult] = useState("");
    const [loadResult, setLoadResult] = useState("loading");
    const {token} = useSelector(state => state.auth);
    const navigate = useNavigate();

    const send = () => {
        axios.post("/api/settings/paymentTerminals", {
            name: paymentTerminal.name,
            provider: paymentTerminal.provider,
            terminal: paymentTerminal.terminal,
            terminalTest: paymentTerminal.terminalTest,
            enableTestTerminal: paymentTerminal.enableTestTerminal,
            enableSendInOFD: paymentTerminal.enableSendInOFD,
            receiptConfig: paymentTerminal.receiptConfig,
        }, {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setSendResult("notAccess");
                } else {
                    navigate("/settings/paymentTerminals");
                }
            })
    };

    const getData = () => {
        axios.get("/api/settings/paymentTerminals/available", {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadResult("notAccess");
                } else if (data.data === []) {
                    setLoadResult("notFound");
                } else {
                    setAvailable(data.data);
                    console.log(data);
                    setPaymentTerminal({
                        ...paymentTerminal,
                        provider: data.data.providers[0],
                        receiptConfig: {
                            ...paymentTerminal.receiptConfig,
                            FFDVersion: data.data.ffdVersions[0],
                            taxation: data.data.taxations[0],
                            tax: data.data.tax[0],
                            paymentObject: data.data.paymentObject[0],
                            paymentMethod: data.data.paymentMethod[0],
                            measurementUnit: data.data.measurementUnit[0],
                        },
                    });
                    setTimeout(() => setLoadResult("ok"), 500);
                }
            })
    };

    useEffect(() => {
        if (loadResult === "loading") {
            getData();
        }
    }, [loadResult]);

    return <div style={{padding: "10px"}}>
        {
            loadResult === "loading"
                ? <Spinner size={100}/>
                : null
        }
        {
            loadResult === "ok"
                ? <>
                    <h2>Добавление платежного терминала</h2>
                    <div style={{display: "flex", gap: "10px"}}>
                        <FormGroup
                            label="Название терминала"
                            labelFor="name-input"
                        >
                            <InputGroup
                                id="name-input"
                                onChange={(e) => {
                                    setPaymentTerminal({
                                        ...paymentTerminal,
                                        name: e.target.value,
                                    })
                                }}
                            />
                        </FormGroup>
                        <FormGroup
                            label="Провайдер"
                            labelFor="provider-input"
                        >
                            <Select
                                id="provider-input"
                                items={available.providers}
                                itemRenderer={(item) => {
                                    return <MenuItem text={item} onClick={() => setPaymentTerminal({
                                        ...paymentTerminal,
                                        provider: item,
                                    })}/>
                                }}
                                filterable={false}
                            >
                                <Button
                                    rightIcon="double-caret-vertical">{(available.providers.find(provider => provider === paymentTerminal.provider))}</Button>
                            </Select>
                        </FormGroup>
                    </div>
                    <h4>Доступы</h4>
                    <div style={{display: "flex", gap: "10px"}}>
                        <FormGroup
                            label="ID терминала"
                            labelFor="terminal-id-input"
                        >
                            <InputGroup
                                id="terminal-id-input"
                                onChange={(e) => {
                                    setPaymentTerminal({
                                        ...paymentTerminal,
                                        terminal: {
                                            ...paymentTerminal.terminal,
                                            terminalId: e.target.value,
                                        },
                                    })
                                }}
                            />
                        </FormGroup>
                        <FormGroup
                            label="Первый пароль"
                            labelFor="key-first-input"
                        >
                            <InputGroup
                                id="key-first-input"
                                onChange={(e) => {
                                    setPaymentTerminal({
                                        ...paymentTerminal,
                                        terminal: {
                                            ...paymentTerminal.terminal,
                                            keyFirst: e.target.value,
                                        },
                                    })
                                }}
                            />
                        </FormGroup>
                        <FormGroup
                            label="Второй пароль"
                            labelInfo="(при наличии)"
                            labelFor="key-second-input"
                        >
                            <InputGroup
                                id="key-second-input"
                                onChange={(e) => {
                                    setPaymentTerminal({
                                        ...paymentTerminal,
                                        terminal: {
                                            ...paymentTerminal.terminal,
                                            keySecond: e.target.value,
                                        },
                                    })
                                }}
                            />
                        </FormGroup>
                    </div>
                    <Switch checked={paymentTerminal.enableTestTerminal} onChange={(e) => setPaymentTerminal({
                        ...paymentTerminal,
                        enableTestTerminal: e.target.checked,
                    })} labelElement="Перенаправить все запросы на тестовый терминал" />
                    {
                        paymentTerminal.enableTestTerminal
                            ? <>
                                <h4>Тестовые доступы</h4>
                                <div style={{display: "flex", gap: "10px"}}>
                                    <FormGroup
                                        label="ID терминала"
                                        labelFor="terminal-id-test-input"
                                    >
                                        <InputGroup
                                            id="terminal-id-test-input"
                                            onChange={(e) => {
                                                setPaymentTerminal({
                                                    ...paymentTerminal,
                                                    terminalTest: {
                                                        ...paymentTerminal.terminalTest,
                                                        terminalId: e.target.value,
                                                    },
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        label="Первый пароль"
                                        labelFor="key-first-test-input"
                                    >
                                        <InputGroup
                                            id="key-first-test-input"
                                            onChange={(e) => {
                                                setPaymentTerminal({
                                                    ...paymentTerminal,
                                                    terminal: {
                                                        ...paymentTerminal.terminalTest,
                                                        keyFirst: e.target.value,
                                                    },
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        label="Второй пароль"
                                        labelInfo="(при наличии)"
                                        labelFor="key-second-test-input"
                                    >
                                        <InputGroup
                                            id="key-second-test-input"
                                            onChange={(e) => {
                                                setPaymentTerminal({
                                                    ...paymentTerminal,
                                                    terminalTest: {
                                                        ...paymentTerminal.terminalTest,
                                                        keySecond: e.target.value,
                                                    },
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                            </>
                            : null
                    }
                    <Switch checked={paymentTerminal.enableSendInOFD} onChange={(e) => setPaymentTerminal({
                        ...paymentTerminal,
                        enableSendInOFD: e.target.checked,
                    })} labelElement="Отправлять данные в ОФД" />
                    {
                        paymentTerminal.enableSendInOFD
                            ? <>
                                <h4>Данные для отправки в чек</h4>
                                <div style={{display: "flex", gap: "10px"}}>
                                    <FormGroup
                                        label="Название товара\услуги в чеке по умолчанию"
                                        labelFor="name-default-input"
                                    >
                                        <InputGroup
                                            id="name-default-input"
                                            onChange={(e) => {
                                                setPaymentTerminal({
                                                    ...paymentTerminal,
                                                    receiptConfig: {
                                                        ...paymentTerminal.receiptConfig,
                                                        defaultNameItem: e.target.value,
                                                    },
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        label="Система налогооблажения"
                                        labelFor="taxation-input"
                                    >
                                        <Select
                                            id="taxation-input"
                                            items={available.taxations}
                                            itemRenderer={(item) => {
                                                return <MenuItem text={item} onClick={() => setPaymentTerminal({
                                                    ...paymentTerminal,
                                                    receiptConfig: {
                                                        ...paymentTerminal.receiptConfig,
                                                        taxation: item,
                                                    },
                                                })}/>
                                            }}
                                            filterable={false}
                                        >
                                            <Button
                                                rightIcon="double-caret-vertical">{(available.taxations.find(taxation => taxation === paymentTerminal.receiptConfig.taxation))}</Button>
                                        </Select>
                                    </FormGroup>
                                    <FormGroup
                                        label="Версия ФФД"
                                        labelFor="FFDVersion-input"
                                    >
                                        <Select
                                            id="FFDVersion-input"
                                            items={available.ffdVersions}
                                            itemRenderer={(item) => {
                                                return <MenuItem text={item} onClick={() => setPaymentTerminal({
                                                    ...paymentTerminal,
                                                    receiptConfig: {
                                                        ...paymentTerminal.receiptConfig,
                                                        FFDVersion: item,
                                                    },
                                                })}/>
                                            }}
                                            filterable={false}
                                        >
                                            <Button
                                                rightIcon="double-caret-vertical">{(available.ffdVersions.find(ffd => ffd === paymentTerminal.receiptConfig.FFDVersion))}</Button>
                                        </Select>
                                    </FormGroup>
                                </div>
                                <div style={{display: "flex", gap: "10px"}}>
                                    <FormGroup
                                        label="НДС"
                                        labelFor="tax-input"
                                    >
                                        <Select
                                            id="tax-input"
                                            items={available.tax}
                                            itemRenderer={(item) => {
                                                return <MenuItem text={item} onClick={() => setPaymentTerminal({
                                                    ...paymentTerminal,
                                                    receiptConfig: {
                                                        ...paymentTerminal.receiptConfig,
                                                        tax: item,
                                                    },
                                                })}/>
                                            }}
                                            filterable={false}
                                        >
                                            <Button
                                                rightIcon="double-caret-vertical">{(available.tax.find(tax => tax === paymentTerminal.receiptConfig.tax))}</Button>
                                        </Select>
                                    </FormGroup>
                                    <FormGroup
                                        label="Признак предмета"
                                        labelFor="payment-object-input"
                                    >
                                        <Select
                                            id="payment-object-input"
                                            items={available.paymentObject}
                                            itemRenderer={(item) => {
                                                return <MenuItem text={item} onClick={() => setPaymentTerminal({
                                                    ...paymentTerminal,
                                                    receiptConfig: {
                                                        ...paymentTerminal.receiptConfig,
                                                        paymentObject: item,
                                                    },
                                                })}/>
                                            }}
                                            filterable={false}
                                        >
                                            <Button
                                                rightIcon="double-caret-vertical">{(available.paymentObject.find(obj => obj === paymentTerminal.receiptConfig.paymentObject))}</Button>
                                        </Select>
                                    </FormGroup>
                                    <FormGroup
                                        label="Метод расчета"
                                        labelFor="payment-method-input"
                                    >
                                        <Select
                                            id="payment-method-input"
                                            items={available.paymentMethod}
                                            itemRenderer={(item) => {
                                                return <MenuItem text={item} onClick={() => setPaymentTerminal({
                                                    ...paymentTerminal,
                                                    receiptConfig: {
                                                        ...paymentTerminal.receiptConfig,
                                                        paymentMethod: item,
                                                    },
                                                })}/>
                                            }}
                                            filterable={false}
                                        >
                                            <Button
                                                rightIcon="double-caret-vertical">{(available.paymentMethod.find(mtd => mtd === paymentTerminal.receiptConfig.paymentMethod))}</Button>
                                        </Select>
                                    </FormGroup>
                                </div>
                                <div style={{display: "flex", gap: "10px"}}>
                                    <FormGroup
                                        label="Единица измерения"
                                        labelFor="measurementUnit-input"
                                    >
                                        <Select
                                            id="measurementUnit-input"
                                            items={available.measurementUnit}
                                            itemRenderer={(item) => {
                                                return <MenuItem text={item} onClick={() => setPaymentTerminal({
                                                    ...paymentTerminal,
                                                    receiptConfig: {
                                                        ...paymentTerminal.receiptConfig,
                                                        measurementUnit: item,
                                                    },
                                                })}/>
                                            }}
                                            filterable={false}
                                        >
                                            <Button
                                                rightIcon="double-caret-vertical">{(available.measurementUnit.find(mu => mu === paymentTerminal.receiptConfig.measurementUnit))}</Button>
                                        </Select>
                                    </FormGroup>
                                </div>
                            </>
                            : null
                    }
                    <Button onClick={send} loading={sendResult === "loading"}>Сохранить</Button>
                </>
                : null
        }
    </div>
};