import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, FormGroup, InputGroup, MenuItem, Spinner, Switch, TextArea} from "@blueprintjs/core";
import axios from "axios";
import {useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate, useParams} from "react-router";
import {Select} from "@blueprintjs/select";

export const EditRoomPage = () => {
    const [room, setRoom] = useState({
        priceCustomPolicyEnable: false,
        priceCustomPolicy: {
            "1": 0,
            "7": 0,
            "30": 0,
        },
    });
    const [loadResult, setLoadResult] = useState("loading");
    const [sendResult, setSendResult] = useState("");
    const {token} = useSelector(state => state.auth);
    const navigate = useNavigate();
    const {id} = useParams();

    const load = () => {
        axios.get("/api/rooms/" + id, {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadResult("notAccess");
                } else if (!data.data) {
                    setLoadResult("notFound");
                } else {
                    setLoadResult("");
                    setRoom(data.data);
                }
            });
    };

    useEffect(() => {
        if (loadResult === "loading") {
            load();
        }
    }, [loadResult]);

    const send = () => {
        axios.put("/api/rooms/" + id, {
            priceCustomPolicyEnable: room.priceCustomPolicyEnable,
            priceCustomPolicy: room.priceCustomPolicy,
        }, {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setSendResult("notAccess");
                } else {
                    navigate("/rooms");
                }
            })
    };

    return <div style={{padding: "10px"}}>
        {
            loadResult !== "loading" && loadResult === ""
                ? <>
                    <h2>{room.hotel.shortName}/{room.no}</h2>
                    <Switch checked={room.priceCustomPolicyEnable} onChange={(e) => setRoom({
                        ...room,
                        priceCustomPolicyEnable: e.target.checked,
                    })} labelElement="Индивидуальная ценовая политика" />
                    {
                        room.priceCustomPolicyEnable
                            ? <>
                                <h4>Цены</h4>
                                <div style={{display: "flex", gap: "10px"}}>
                                    <FormGroup
                                        label="Сутки"
                                        labelFor="price1-input"
                                    >
                                        <InputGroup
                                            id="price1-input"
                                            defaultValue={room.priceCustomPolicy["1"]}
                                            onChange={(e) => {
                                                setRoom({
                                                    ...room,
                                                    priceCustomPolicy: {
                                                        ...room.priceCustomPolicy,
                                                        "1": e.target.value,
                                                    },
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        label="Неделя"
                                        labelFor="price7-input"
                                    >
                                        <InputGroup
                                            id="price7-input"
                                            defaultValue={room.priceCustomPolicy["7"]}
                                            onChange={(e) => {
                                                setRoom({
                                                    ...room,
                                                    priceCustomPolicy: {
                                                        ...room.priceCustomPolicy,
                                                        "7": e.target.value,
                                                    },
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        label="Месяц"
                                        labelFor="price30-input"
                                    >
                                        <InputGroup
                                            id="price30-input"
                                            defaultValue={room.priceCustomPolicy["30"]}
                                            onChange={(e) => {
                                                setRoom({
                                                    ...room,
                                                    priceCustomPolicy: {
                                                        ...room.priceCustomPolicy,
                                                        "30": e.target.value,
                                                    },
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                            </>
                            : null
                    }
                    <Button onClick={send} loading={sendResult === "loading"}>Сохранить</Button>
                </>
                : <Spinner size={100}/>
        }

    </div>
};