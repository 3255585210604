import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    Callout,
    DialogBody,
    DialogFooter,
    FormGroup,
    InputGroup,
    Intent,
    PanelStack2
} from "@blueprintjs/core";
import {QRCode} from "react-qrcode-logo";
import {AppToaster} from "../../../App";
import {useSelector} from "react-redux";
import moment from "moment";
import {Button, ButtonGroup} from "react-bootstrap";
import MasonryResponsive from "react-responsive-masonry/lib/ResponsiveMasonry";
import Masonry from "react-responsive-masonry";
import SBPSVG from "../../../Icons/Logos/sbp.svg";
import {Center} from "react-containers";

export const PayClientModal = (props) => {
    const {token} = useSelector(state => state.auth);

    const getDays = (days) => {
        if (days >= 30) {
            return {days: 1, type: 'M'};
        } else {
            return {days, type: 'd'};
        }
    };

    const getPrice = (client, days) => {
        if (days >= 30) {
            return client.prices['30'];
        } else if (days < 7) {
            return client.prices['1'] * days;
        } else {
            return client.prices['7'];
        }
    };

    const PanelSetPayMethod = props => {
        return <>
            <MasonryResponsive
                columnsCountBreakPoints={{350: 3, 600: 3, 900: 3}}
            >
                <Masonry gutter="10px">
                    <Button className="optibiz-transparent-btn t-25 big" large onClick={() => props.openPanel({
                        props: {
                            onUpdate: props.onUpdate,
                            onClose: props.onClose,
                            id: props.id,
                            client: props.client,
                        },
                        renderPanel: PanelSetDayManual,
                        title: "Ввол кол-во дней",
                    })}><i className="fa-solid fa-money-bill"/> <span>Внести вручную</span></Button>
                    <Button className="optibiz-transparent-btn t-25 big" large onClick={() => props.openPanel({
                        props: {
                            onUpdate: props.onUpdate,
                            onClose: props.onClose,
                            id: props.id,
                            client: props.client,
                        },
                        renderPanel: PanelSetDay,
                        title: "Ввол кол-во дней",
                    })}><i className="fa-solid fa-cash-register"/> <span>Онлайн-оплата</span></Button>
                    <Button className="optibiz-transparent-btn t-25 big" large onClick={props.onUpdate}><i
                        className="fa-solid fa-xmark"/> <span>Без оплаты</span></Button>
                </Masonry>
            </MasonryResponsive>
        </>
    };

    const PanelSetDay = props => {
        const [days, setDays] = useState(7);

        const pay = () => {
            axios.post("/api/clients/" + props.id + "/pay", {days}, {headers: {token}})
                .then(data => {
                    props.openPanel({
                        props: {
                            paymentLink: data.data.paymentLink,
                            sbpURL: data.data.meta.sbpURL,
                            orderId: data.data.id,
                            onUpdate: props.onUpdate,
                            provider: data.data.paymentTerminal.provider,
                            bankPayURL: data.data.meta.bankPayURL,
                        },
                        renderPanel: PanelPaymentLink,
                        title: "Оплата",
                    });
                })
        };

        return <>
            <FormGroup
                label="Кол-во дней"
                labelFor="days-input"
            >
                <InputGroup
                    id="days-input"
                    value={days}
                    onInput={(e) => {
                        setDays(e.target.value);
                    }}
                />
            </FormGroup>
            <ButtonGroup large>
                <Button onClick={() => setDays(1)}>1</Button>
                <Button onClick={() => setDays(2)}>2</Button>
                <Button onClick={() => setDays(7)}>Неделя</Button>
                <Button onClick={() => setDays(30)}>Месяц</Button>
            </ButtonGroup>
            <table>
                <thead>
                <th>Сутки</th>
                <th>Неделя</th>
                <th>Месяц</th>
                </thead>
                <tbody>
                <tr style={{textAlign: "center"}}>
                    <td>{props.client.place.room.priceCustomPolicyEnable ? props.client.place.room.priceCustomPolicy['1'] : props.client.place.room.hotel.priceDefault['1']} ₽</td>
                    <td>{props.client.place.room.priceCustomPolicyEnable ? props.client.place.room.priceCustomPolicy['7'] : props.client.place.room.hotel.priceDefault['7']} ₽</td>
                    <td>{props.client.place.room.priceCustomPolicyEnable ? props.client.place.room.priceCustomPolicy['30'] : props.client.place.room.hotel.priceDefault['30']} ₽</td>
                </tr>
                </tbody>
            </table>
            <br/>
            <p><b>Будет оплачено
                до: {moment(props.client.lastPay).add(getDays(days).days, getDays(days).type).format("DD/MM ddd")}</b>
            </p>
            <p><b>Стоимость: {getPrice(props.client, days)} ₽</b></p>
            <Button intent={Intent.PRIMARY} onClick={pay}>Продлить</Button>
        </>
    };

    const PanelSetDayManual = props => {
        const [days, setDays] = useState(7);

        const pay = () => {
            axios.put("/api/clients/" + props.id + "/manualPay", {days}, {headers: {token}})
                .then(() => {
                    props.onUpdate();
                })
        };

        return <>
            <FormGroup
                label="Кол-во дней"
                labelFor="days-input"
            >
                <InputGroup
                    id="days-input"
                    value={days}
                    onInput={(e) => {
                        setDays(e.target.value);
                    }}
                />
            </FormGroup>
            <ButtonGroup large>
                <Button onClick={() => setDays(1)}>1</Button>
                <Button onClick={() => setDays(2)}>2</Button>
                <Button onClick={() => setDays(7)}>Неделя</Button>
                <Button onClick={() => setDays(30)}>Месяц</Button>
            </ButtonGroup>
            <table>
                <thead>
                <th>Сутки</th>
                <th>Неделя</th>
                <th>Месяц</th>
                </thead>
                <tbody>
                <tr style={{textAlign: "center"}}>
                    <td>{props.client.place.room.priceCustomPolicyEnable ? props.client.place.room.priceCustomPolicy['1'] : props.client.place.room.hotel.priceDefault['1']} ₽</td>
                    <td>{props.client.place.room.priceCustomPolicyEnable ? props.client.place.room.priceCustomPolicy['7'] : props.client.place.room.hotel.priceDefault['7']} ₽</td>
                    <td>{props.client.place.room.priceCustomPolicyEnable ? props.client.place.room.priceCustomPolicy['30'] : props.client.place.room.hotel.priceDefault['30']} ₽</td>
                </tr>
                </tbody>
            </table>
            <br/>
            <p><b>Будет оплачено
                до: {moment(props.client.lastPay).add(getDays(days).days, getDays(days).type).format("DD/MM ddd")}</b>
            </p>
            <p><b>Стоимость: {getPrice(props.client, days)} ₽</b></p>
            <Button intent={Intent.PRIMARY} onClick={pay}>Продлить</Button>
        </>
    };

    const PanelQRCodePage = props => {
        return <>
            <QRCode value={props.paymentLink}/>
            <br/>
            <Callout intent={Intent.WARNING} title="Это не QR СПБ">
                Этот QR-код перенаправит на страницу оплаты, а не СПБ. Сканировать стандартным QR-сканером.
            </Callout>
        </>
    };

    const PanelQRCodeSBP = props => {
        return <>
            <Center>
                <QRCode logoImage={SBPSVG} size={300} logoHeight={51} logoWidth={95} logoPadding={5} qrStyle="dots" removeQrCodeBehindLogo={true} value={props.sbpURL}/>
            </Center>
        </>
    };

    const PanelQRCodeTinkoffPay = props => {
        return <>
            <Center>
                <QRCode logoImage={SBPSVG} size={300} logoHeight={51} logoWidth={95} logoPadding={5} qrStyle="dots" removeQrCodeBehindLogo={true} value={props.url}/>
            </Center>
        </>
    };

    const PanelSendViaWhatsApp = props => {
        const [phone, setPhone] = useState("");

        return <>
            <FormGroup
                label="Номер телефона WhatsApp"
                labelInfo="(должен начинаться с 7 без +)"
                labelFor="days-input"
            >
                <InputGroup
                    id="days-input"
                    placeholder="79999999999"
                    type="number"
                    onChange={(e) => {
                        setPhone(e.target.value);
                    }}
                />
            </FormGroup>
            <Button intent={Intent.PRIMARY} onClick={() => {
                window.open(`https://wa.me/${phone}?text=${encodeURI(props.paymentLink)}`);
            }}>Отправить</Button>
        </>
    };

    const PanelPaymentLink = props => {
        useEffect(() => {
            const intervalCheckOrder = setInterval(() => {
                axios.get("/api/orders/" + props.orderId, {headers: {token}}).then(data => {
                    if (data.data.status === "ok") {
                        props.onUpdate();
                    }
                });
            }, 2500);

            return () => clearInterval(intervalCheckOrder);
        }, []);

        return <>
            <p><a target="_blank" href={props.paymentLink}>{props.paymentLink}</a></p>
            <br/>
            <MasonryResponsive columnsCountBreakPoints={{350: 3}}>
                <Masonry gutter="10px">
                    <Button className="optibiz-transparent-btn t-25 big" large
                            onClick={() => window.open(props.paymentLink, "_blank")}><i
                        className="fa-solid fa-link"/> Открыть страницу оплаты</Button>
                    <Button className="optibiz-transparent-btn t-25 big" large
                            onClick={() => navigator.clipboard.writeText(props.paymentLink).then(() => AppToaster.show({
                                intent: Intent.SUCCESS,
                                message: "Скопировано"
                            }))}><i className="fa-solid fa-copy"/> Скопировать ссылку</Button>
                    {
                        typeof navigator.share !== "undefined"
                            ?
                            <Button className="optibiz-transparent-btn t-25 big" large onClick={() => navigator.share({
                                title: "Ссылка для оплаты",
                                url: props.paymentLink
                            })}><i className="fa-solid fa-share-nodes"/> Поделиться</Button>
                            : null
                    }
                    <Button className="optibiz-transparent-btn t-25 big" large onClick={() => props.openPanel({
                        props: {
                            paymentLink: props.paymentLink,
                        },
                        renderPanel: PanelSendViaWhatsApp,
                        title: "Отправить на WhatsApp",
                    })}><i className="fa-brands fa-whatsapp"/> Отправить на WhatsApp</Button>
                    <Button className="optibiz-transparent-btn t-25 big" large onClick={() => props.openPanel({
                        props: {
                            paymentLink: props.paymentLink,
                        },
                        renderPanel: PanelQRCodePage,
                        title: "QR-код",
                    })}><i className="fa-solid fa-qrcode"/> Показать QR-код страницы</Button>
                    <Button className="optibiz-transparent-btn t-25 big" large onClick={() => props.openPanel({
                        props: {
                            sbpURL: props.sbpURL,
                            orderId: props.orderId,
                        },
                        renderPanel: PanelQRCodeSBP,
                        title: "СПБ",
                    })}><i><img src={SBPSVG} alt=""/></i> СПБ</Button>
                    {
                        props.provider === "tinkoff" && props.bankPayURL
                            ? <Button className="optibiz-transparent-btn t-25 big" large onClick={() => props.openPanel({
                                props: {
                                    url: props.bankPayURL,
                                    orderId: props.orderId,
                                },
                                renderPanel: PanelQRCodeTinkoffPay,
                                title: "Tinkoff Pay",
                            })}><i><img src={SBPSVG} alt=""/></i> Tinkoff Pay</Button>
                            : null
                    }
                </Masonry>
            </MasonryResponsive>
        </>
    };

    const initialPanel = {
        props: {
            id: props.id,
            onUpdate: props.onUpdate,
            onClose: props.onClose,
            client: props.client,
        },
        renderPanel: PanelSetPayMethod,
        title: "Выбор способа оплаты",
    };

    const [currentPanelStack, setCurrentPanelStack] = useState([initialPanel]);

    const addToPanelStack = React.useCallback(
        (newPanel) => setCurrentPanelStack(stack => [...stack, newPanel]),
        [],
    );

    const removeFromPanelStack = React.useCallback(() => setCurrentPanelStack(stack => stack.slice(0, -1)), []);

    return <>
        <DialogBody className="stack-dialog">
            <PanelStack2
                className="stack"
                stack={currentPanelStack}
                onOpen={addToPanelStack}
                onClose={removeFromPanelStack}
            />
        </DialogBody>
        <DialogFooter actions={<>
            <Button onClick={() => props.onClose()}>Отмена</Button>
        </>}/>
    </>
};