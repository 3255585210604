import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, FormGroup, InputGroup, MenuItem, Spinner, TextArea} from "@blueprintjs/core";
import axios from "axios";
import {useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate, useParams} from "react-router";
import {Select} from "@blueprintjs/select";

export const GetRoomPage = () => {
    const [room, setRoom] = useState({});
    const [loadResult, setLoadResult] = useState("loading");
    const {token} = useSelector(state => state.auth);
    const navigate = useNavigate();
    const {id} = useParams();

    const load = () => {
        axios.get("/api/rooms/" + id, {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadResult("notAccess");
                } else if (!data.data) {
                    setLoadResult("notFound");
                } else {
                    setLoadResult("");
                    setRoom(data.data);
                }
            });
    };

    useEffect(() => {
        if (loadResult === "loading") {
            load();
        }
    }, [loadResult]);

    return <div style={{padding: "10px"}}>
        {
            loadResult !== "loading" && loadResult === ""
                ? <>
                    <h2>Комната {room.hotel.shortName}/{room.no}</h2>
                    <ButtonGroup>
                        <Button icon="edit" onClick={() => navigate("/rooms/" + room.id + "/edit")}>Редактировать</Button>
                    </ButtonGroup>
                    <br/><br/>
                    <table className="info">
                        <tbody>
                        <tr>
                            <td>ID</td>
                            <td>{room.id}</td>
                        </tr>
                        <tr>
                            <td>Название</td>
                            <td>{room.hotel.shortName}/{room.no}</td>
                        </tr>
                        <tr>
                            <td>Отель</td>
                            <td>{room.hotel.name}</td>
                        </tr>
                        <tr>
                            <td>Тип</td>
                            <td>{room.type}</td>
                        </tr>
                        <tr>
                            <td>Занято</td>
                            <td>{room.countFullPlaces}/{room.countPlaces} ({((room.countFullPlaces / room.countPlaces) * 100).toFixed(2)}%)</td>
                        </tr>
                        {
                            room.priceCustomPolicyEnable
                                ? <>
                                    <tr className="bold">
                                        <td colSpan={2}>Индивидуальная ценовая политика</td>
                                    </tr>
                                    <tr>
                                        <td>Сутки</td>
                                        <td>{room.priceCustomPolicy["1"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Неделя</td>
                                        <td>{room.priceCustomPolicy["7"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Месяц</td>
                                        <td>{room.priceCustomPolicy["30"]}</td>
                                    </tr>
                                </>
                                : null
                        }
                        <tr className="bold">
                            <td colSpan={2}>Дополнительная информация</td>
                        </tr>
                        <tr>
                            <td>Создан</td>
                            <td>{room.createdAt}</td>
                        </tr>
                        <tr>
                            <td>Обновлен</td>
                            <td>{room.updatedAt}</td>
                        </tr>
                        </tbody>
                    </table>
                </>
                : <Spinner size={100}/>
        }

    </div>
};