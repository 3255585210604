import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, Intent, Spinner} from "@blueprintjs/core";
import axios from "axios";
import {useSelector} from "react-redux";
import {Cell, Column, Table2} from "@blueprintjs/table";
import {useNavigate} from "react-router";

export const PaymentTerminalsPage = () => {
    const [paymentTerminals, setPaymentTerminals] = useState([{}]);
    const [loadingResult, setLoadingResult] = useState("loading");
    const {token} = useSelector(state => state.auth);
    const navigate = useNavigate();

    const getData = () => {
        axios.get("/api/settings/paymentTerminals", {headers: {token}})
            .then(data => {
                if (data.status === 403) {
                    setLoadingResult("notAccess");
                } else if (data.data === []) {
                    setLoadingResult("notFound");
                } else {
                    setPaymentTerminals(data.data);
                    setLoadingResult("ok");
                }
            })
    };

    const deleteData = (id) => {
        axios.delete("/api/settings/paymentTerminals/" + id, {headers: {token}})
            .then(() => setLoadingResult("update"));
    };

    useEffect(() => {
        if (loadingResult === "loading" || loadingResult === "update") {
            getData();
        }
    }, [loadingResult]);

    return <div style={{padding: "10px"}}>
        <ButtonGroup>
            <Button icon="plus" onClick={() => navigate("/settings/paymentTerminals/add")}>Новый платержный терминал</Button>
            <Button icon="repeat" onClick={() => setLoadingResult("update")}
                    loading={loadingResult === "update"}>Обновить</Button>
        </ButtonGroup>
        <br/><br/>
        {
            loadingResult === "ok"
                ? <table className="table" border="1" style={{borderStyle: "solid"}}>
                    <thead>
                    <tr>
                        <th width="40" rowSpan={2}>ID</th>
                        <th width="40" rowSpan={2}>Название</th>
                        <th width="40" rowSpan={2}>Провайдер</th>
                        <th width="150" rowSpan={2}>Тестовый режим</th>
                        <th width="80" rowSpan={2}>Отправка данных в ОФД</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        paymentTerminals.map(h => {
                            return <tr>
                                <td>{h.id}</td>
                                <td>{h.name}</td>
                                <td>{h.provider}</td>
                                <td>{h.enableTestTerminal ? "+" : "-"}</td>
                                <td>{h.enableSendInOFD ? "+" : "-"}</td>
                                <td>
                                    <Button icon="more" onClick={() => navigate("/settings/paymentTerminals/" + h.id)}/>
                                    <Button icon="edit" onClick={() => navigate("/settings/paymentTerminals/" + h.id + "/edit")}/>
                                    <Button icon="delete" intent={Intent.DANGER} onClick={() => deleteData(h.id)}/>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
                : <Spinner size={100}/>
        }
    </div>
};